import { configureStore } from "@reduxjs/toolkit";
import { api } from "./app/services/api";

const initialState = {
  loginUser: {
    user: "",
  },
};

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(api.middleware);
  },
  preloadedState: initialState, // Sets the initial state of the store
});

export default store;
