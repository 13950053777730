import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import CustomInputField from "../../components/custom_input_field";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../../../assets/theft_reporting/logo.png";
import nlogo from "../../../../assets/theft_reporting/nlogo.png";

import { useLoginMutation } from "./service";

export default function LoginScreen() {
  const [loginUser, { isLoading }] = useLoginMutation();
  console.log("useLogin", useLoginMutation());
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Password is required."),
      email: Yup.string().required("Email is required."),
    }),
    onSubmit: async (payload) => {
      try {
        const result = await loginUser(payload).unwrap(); // Await the mutation and unwrap the result
        if (result?.status === 200) {
          localStorage.setItem("user", result.data);
          toast.success("Successfully Logged In");
          history.push("/"); // Redirect to the home page
        } else {
          toast.error("Invalid Credentials!");
        }
      } catch (error) {
        console.log("error", error);
        toast.error(error.message || "Login failed");
      }
    },
  });

  //   const handleRedirect = () => {
  //     history.push("/register");
  //   };

  return (
    <>
      <div className="auth_main">
        <div className="content">
          <div className="auth_login_box">
            <img src={nlogo} className="logo-img" />
            <h2 className="auth-welcome mt-3 text-align-center">Login</h2>
            <form
              className=" p-fluid justify-content-center align-items-left mt-5"
              onSubmit={formik.handleSubmit}
            >
              <CustomInputField
                label="Email"
                iden="email"
                formik={formik}
                placeHolder="Email"
                type="text"
                maxLength={20}
                onKeyUp={(e) => {
                  // e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  formik.setFieldValue("email", e.target.value);
                }}
                icon="pi-email"
              />
              <CustomInputField
                label="Password"
                iden="password"
                formik={formik}
                placeHolder="Password"
                type="password"
                feedback={false}
                maxLength={30}
                onKeyUp={(e) => {
                  // e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  formik.setFieldValue("password", e.target.value);
                }}
                icon="pi-lock"
              />
              <br />
              <Button
                type="submit"
                className="customButton"
                label="Login"
                loading={isLoading}
              />
            </form>
            {/* <p className="text-center mt-2 an-account">
              If you have an account?{" "}
              <a onClick={handleRedirect}>Signup here</a>
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
}
