import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import { Panel } from "primereact/panel";
import { Menu } from "primereact/menu";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete,
  DirectionsRenderer,
  Polyline,
} from "@react-google-maps/api";
import { format } from "date-fns";

import { InputText } from "primereact/inputtext";
import { useGetAllReportsMutation } from "../../Incident_Records/manager/report_service";
import {
  useGetCitiesQuery,
  useGetEyeColorQuery,
  useGetHairColorsQuery,
  useGetIncidentTypesQuery,
  useGetStatusQuery,
} from "../manager/service";
import CustomLoading from "../../../components/custom_spinner";
const libraries = ["places"];
const googleMapsApiKey = "AIzaSyDCF5qATVMCFMC408jR-mwsZGkzUSB_LJM";
const Dashboard = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectIncidentType, setIncidentType] = useState(null);
  const [selectedStatus, setStatus] = useState(null);
  const [selectedEyeColor, setEyeColor] = useState(null);
  const [selectedHairColor, setHairColor] = useState(null);
  const menu = useRef(null);

  const [date, setDate] = useState(null);
  const [call, setCall] = useState(false);
  const [toDate, setToDate] = useState(null);
  const [directions, setDirections] = useState(null);
  const [originAutocomplete, setOriginAutocomplete] = useState(null);
  const [destinationAutocomplete, setDestinationAutocomplete] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [polylinePath, setPolylinePath] = useState([]);
  const [getReportRecords, { data, error, isLoading }] =
    useGetAllReportsMutation();
  // const { data:cities } = useGetCitiesQuery();
  const { data: eyeColors = [] } = useGetEyeColorQuery();
  const { data: hairColors = [] } = useGetHairColorsQuery();
  const { data: statuses = [] } = useGetStatusQuery();
  const { data: incidentTypes = [] } = useGetIncidentTypesQuery();
  const [isLoad, setIsLoad] = useState(true);
  console.log("statuses", statuses?.data);

  const cities = [
    { name: "Islamabad", code: "IS" },
    { name: "Lahore", code: "LAR" },
    { name: "Karachi", code: "KAR" },
    { name: "Rawalpindi", code: "RAW" },
  ];
  const menuItems = [
    {
      items: [
        {
          template: () => (
            <div className="p-field p-fluid filters-label">
              {/* <div className="md:col-2 lg:col-2 sm:col-12 col-12 p-2"> */}
              {/* <p className="p-button w-full ">Filters</p> */}

              <Button
                className="p-button w-full mr-4 border-round-md cursor-auto"
                label="Set Filters"
              // icon="pi pi-sliders-h"
              />
              <i
                className="pi pi-times cursor-pointer"
                onClick={(e) => {
                  menu.current.toggle(e)
                  setDate(null);
                  setSelectedLocation(null);
                  setStatus(null);
                  setIncidentType(null);
                  setEyeColor(null);
                  setHairColor(null);
                }}
              ></i>
            </div>
          ),
        },
        {
          label: "Location",
          template: () => (
            <div className="p-field p-fluid mt-3">
              <Dropdown
                value={selectedLocation}
                onChange={(e) => setSelectedLocation(e.value)}
                options={cities}
                optionLabel="name"
                placeholder="Select Location"
                appendTo="self"
              />
            </div>
          ),
        },
        {
          label: "Status",
          template: () => (
            <div className="p-field p-fluid mt-3">
              <Dropdown
                value={selectedStatus}
                options={statuses?.data}
                onChange={(e) => setStatus(e.value)}
                placeholder="Select Status"
                appendTo="self"
              />
            </div>
          ),
        },
        {
          label: "Incident Type",
          template: () => (
            <div className="p-field p-fluid mt-3">
              <Dropdown
                value={selectIncidentType}
                options={incidentTypes?.data}
                onChange={(e) => setIncidentType(e.value)}
                placeholder="Select IncidentType"
                appendTo="self"
              />
            </div>
          ),
        },
        {
          label: "Eye Color",
          template: () => (
            <div className="p-field p-fluid mt-3">
              <Dropdown
                value={selectedEyeColor}
                options={eyeColors?.data}
                onChange={(e) => setEyeColor(e.value)}
                placeholder="Select Eye Color"
                appendTo="self"
              />
            </div>
          ),
        },
        {
          label: "Hair Color",
          template: () => (
            <div className="p-field p-fluid mt-3">
              <Dropdown
                value={selectedHairColor}
                options={hairColors?.data}
                onChange={(e) => setHairColor(e.value)}
                placeholder="Select Hair Color"
                appendTo="self"
              />
            </div>
          ),
        },
        {
          label: "Actions",
          template: () => (
            <div
              className="p-field p-fluid mt-4"
              style={{ display: "flex", gap: "10px" }}
            >
              <Button
                label="Cancel"
                className="p-button-secondary w-full border-round-md bg-gray-100 text-black hover:bg-[#0a1322] hover:text-white"
                onClick={() => {
                  setStatus(null);
                  setIncidentType(null);
                  setEyeColor(null);
                }}
              />
              <Button
                label="Apply Filter"
                className="p-button-primary w-full border-round-md bg-blue-500 text-white hover:bg-blue-600 hover:shadow-lg"
                onClick={handleFilterApply}
              />
            </div>
          ),
        },
      ],
    },
  ];

  const onPlaceChanged = (autocomplete, setPoint) => {
    const place = autocomplete?.getPlace();
    if (place.geometry) {
      setPoint({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };

  const calculateRoute = () => {
    if (origin && destination) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin,
          destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
            const path = result.routes[0].overview_path.map((point) => ({
              lat: point.lat(),
              lng: point.lng(),
            }));
            setPolylinePath(path);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  };

  // const incidentTypes = [
  //   { name: "Burglary", code: "IS" },
  //   { name: "Robbery", code: "LAR" },
  //   { name: "Pickpocketing", code: "KAR" },
  //   { name: "Bike Theft", code: "RAW" },
  // ];

  // const statuses = [
  //   { name: "Pending", code: "PEN" },
  //   { name: "In-Progress", code: "IP" },
  //   { name: "Open", code: "OPEN" },
  //   { name: "Closed", code: "CL" },
  // ];

  const positions = [
    { lat: 33.6115, lng: 73.1733 },
    { lat: 24.794, lng: 67.048 },
  ];

  useEffect(() => {
    let queryData = [];
    let from;
    let to;
    if (call === true) {
      // Check if globalFilter has a value and add it to queryData
      if (selectedLocation) {
        queryData.push(`location=${selectedLocation?.name}`);
      }
      if (selectedStatus) {
        queryData.push(`status=${selectedStatus?.name}`);
      }

      if (selectIncidentType) {
        queryData.push(`incidentType=${selectIncidentType?.name}`);
      }
      if (selectedEyeColor) {
        queryData.push(`eyeColor=${selectedEyeColor?.name}`);
      }
      if (selectedHairColor) {
        queryData.push(`hairColor=${selectedHairColor?.name}`);
      }
    }

    // Check if both fromDate and toDate have values, or either one individually
    if (date && date.length > 0) {
      if (date[0] && date[1]) {
        from = format(date[0], "yyyy-MM-dd");
        to = format(date[1], "yyyy-MM-dd");
        queryData.push(`fromDate=${from}&toDate=${to}`);
      } else if (date[0]) {
        from = format(date[0], "yyyy-MM-dd");
        queryData.push(`fromDate=${from}`);
      } else if (date[1]) {
        to = format(date[1], "yyyy-MM-dd");
        queryData.push(`toDate=${to}`);
      }
    }

    const filter = queryData.join("&");

    // Call the function with the filtered queryData object
    getReportRecords(filter);
  }, [date, call]);
  const handleFilterApply = () => {
    setCall(true);
    console.log("Filters Applied", {
      selectedLocation,
      selectedStatus,
      selectIncidentType,
      selectedEyeColor,
      selectedHairColor,
    });
  };
  return (
    <>
      <ToastContainer />
      <div className="card">
        <div className="field col-12">
          <div className="row grid flex justify-content-around">
            <div className="md:col-6 lg:col-6 sm:col-12 col-12"></div>
            <div className="md:col-4 lg:col-4 sm:col-12 col-12">
              <Calendar
                type="date"
                id="date"
                placeholder="Select From Date and To Date"
                value={date}
                className="w-full"
                onChange={(e) => setDate(e.value)}
                showIcon
                selectionMode="multiple"
                readOnlyInput
              />
            </div>
            <div className="md:col-2 lg:col-2 sm:col-12 col-12">
              <Menu
                model={menuItems}
                popup
                ref={menu}
                className="filter-menu"
              />
              <Button
                label="Filters"
                icon="pi pi-sliders-h"
                // className="p-button ml-3 filters-btn"
                className="custom-p-button w-full"
                onClick={(e) => menu.current.toggle(e)}
              />
            </div>
          </div>
          {isLoading ? (
            <CustomLoading isLoading={isLoading} />
          ) : (
            <div className="field-map">
              <LoadScript
                googleMapsApiKey={googleMapsApiKey}
                libraries={libraries}
              // onLoad={() => setIsLoad(false)}
              >
                <GoogleMap
                  onUnmount={() => { }}
                  mapContainerStyle={{ height: "100vh", width: "100%" }}
                  center={{ lat: 33.6115, lng: 73.1733 }}
                  zoom={13}
                >
                  {positions.map((position, idx) => (
                    <Marker
                      key={idx}
                      position={position}
                      style={{ color: "black" }}
                    />
                  ))}
                  {/* {directions && <DirectionsRenderer directions={directions} />}
                {polylinePath.length > 0 && (
                  <Polyline
                    path={polylinePath}
                    options={{ strokeColor: "#FF0000" }}
                  />
                )} */}
                </GoogleMap>

                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    left: 10,
                    zIndex: 1,
                  }}
                >
                  {/* <Autocomplete
                  onLoad={(autocomplete) => setOriginAutocomplete(autocomplete)}
                  onPlaceChanged={() =>
                    onPlaceChanged(originAutocomplete, setOrigin)
                  }
                >
                  <input
                    type="text"
                    placeholder="Enter origin"
                    style={{ width: "200px", height: "40px" }}
                  />
                </Autocomplete> */}
                  {/* <Autocomplete
                  onLoad={(autocomplete) =>
                    setDestinationAutocomplete(autocomplete)
                  }
                  onPlaceChanged={() =>
                    onPlaceChanged(destinationAutocomplete, setDestination)
                  }
                >
                  <input
                    type="text"
                    placeholder="Enter destination"
                    style={{
                      width: "200px",
                      height: "40px",
                      marginTop: "10px",
                    }}
                  />
                </Autocomplete> */}
                  {/* <button
                  onClick={calculateRoute}
                  style={{ marginTop: "10px", color: "black" }}
                >
                  Calculate Route
                </button> */}
                </div>
              </LoadScript>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
