import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import appUrl from "../constants/appUrl";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: appUrl.baseUrl,
    prepareHeaders: (headers) => {
      // Header customization logic can be implemented here
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: ["APP"],
});
