import { api } from "../../../services/api";
export const postsAPI = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (body) => ({
        url: `admin/login`,
        body: body,
        method: "POST",
      }),
      providesTags: [],
    }),
  }),
});

export const { useLoginMutation } = postsAPI;
