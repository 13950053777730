import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const AppTopbar = (props) => {
  const history = useHistory();
  console.log("props: ", props);
  const signoutConfirm = () => {
    confirmDialog({
      message: "Are you sure you want to Logout?",
      icon: "pi pi-exclamation-triangle",
      header: "Confirmation",
      acceptClassName: "p-button p-button p-button-sm width-80",
      accept: () => {
        localStorage.clear();
        history.push("/login");
      },
      reject: () => {},
    });
  };

  return (
    <>
      <ConfirmDialog />
      <div className="layout-topbar">
        <div className="layout-topbar-ellipsis">
          <button
            type="button"
            className="p-link  layout-menu-button layout-topbar-button"
            onClick={props.onToggleMenuClick}
          >
            <i className="pi pi-bars" />
          </button>
          {props.currentRouteLabel && (
            <div className="layout-topbar-heading">
              {props.currentRouteLabel}
            </div>
          )}
        </div>

        <div className="layout-topbar-user">
          <h6>Admin </h6>
          <button
            className="p-link layout-topbar-button"
            onClick={() => {
              signoutConfirm();
            }}
          >
            <i className="pi pi-power-off" />
          </button>
        </div>
      </div>
    </>
  );
};
