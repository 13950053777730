import React, { useState, useRef, useEffect } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import "primereact/resources/themes/saga-blue/theme.css";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { useFormik, Formik, Form, FieldArray } from "formik";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import SignatureCanvas from "react-signature-canvas";
import { Calendar } from "primereact/calendar";
import { useAddReportMutation, useGetReportByIdMutation } from "../../Incident_Records/manager/report_service";
import CustomLoading from "../../../components/custom_spinner";
import { useHistory } from "react-router-dom"; // Import useNavigate
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import appUrl from "../../../../constants/appUrl";

export const IncidentReporting = () => {
  const [isDrawing, setIsDrawing] = useState([]);
  const [signatureImage, setSignatureImage] = useState([]);
  const fileInputRef = useRef(null);
  const [signatureImages, setSignatureImages] = useState({});
  const [disable, setDisable] = useState(false);
  const history = useHistory();
  const { reportId } = useParams();
  const [getReportRecord, { data: reportData, error: reportError, isLoading: isReportLoading }] =
    useGetReportByIdMutation();
  const [AddReportRecord, { data, error, isLoading }] = useAddReportMutation();
  const signatureRef = useRef([]);
  console.log("Report ID:", reportId);
  const handleDrawClick = (index) => {
    // Toggle drawing state for the specific index
    setIsDrawing((prev) => {
      const updatedDrawing = [...prev];
      updatedDrawing[index] = !updatedDrawing[index];
      return updatedDrawing;
    });
  };
  const location = useLocation();
  const { row, editable } = location.state || {};

  const handleClearSignature = (index) => {
    if (signatureRef?.current[index]) {
      signatureRef?.current[index].clear();
    }
  };

  // const handleSaveSignature = (index) => {
  //   console.log("index", index);
  //   console.log("signatureDataUrl", signatureRef.current);
  //   const signatureDataUrl = signatureRef.current[0].toDataURL("image/png");
  //   formik.setFieldValue(`officers.${index}.signature`, signatureDataUrl);
  //   setSignatureImage(signatureDataUrl);
  // };
  const handleSaveSignature = (index) => {
    // Access the signature canvas at the given index
    const signatureCanvas = signatureRef?.current[index];

    // Convert the signature to a base64 string
    const signatureDataUrl = signatureCanvas.toDataURL("image/png");

    // Set the base64 string in the appropriate Formik field
    formik.setFieldValue(
      `officers.${index}.reportingOfficerSignature`,
      signatureDataUrl
    );

    // Optionally, set the signature image in the state if needed
    setSignatureImage((prevState) => {
      const updatedSignatures = [...prevState];
      updatedSignatures[index] = signatureDataUrl; // Set the image for the specific officer
      return updatedSignatures;
    });
    // Log for debugging
    console.log("index", index);
    console.log("signatureDataUrl", signatureDataUrl);

  };

  // const handleButtonClick = (index) => {
  //   console.log("index", index);
  //   signatureRef?.current[index]?.click();
  //   console.log("index", index)
  //   console.log("signatureRef", signatureRef)
  // };

  const handleButtonClick = (index) => {
    if (signatureRef?.current[index]) {
      signatureRef.current[index].click(); // Trigger the hidden file input click
    } else {
      console.error("No signature input found for this index");
    }
  };

  console.log("row", row)
  console.log("editable", editable)
  const initialValues = {
    editable: editable,
    officers: [
      {
        incident: reportId,
        officerName: "",
        station: "",
        badgeNumber: "",
        reportingOfficerSignature: "",
      },
    ],
    // date: "",
    id: reportId,
    incidentNumber: "",
    incidentType: "",
    dateTime: "",
    location: "",
    briefDescription: "",
    weatherCondition: "",
    lightingCondition: "",
    presenseSecurityMeasures: "",
    pictures: [],
    suspects: [
      {
        incident: reportId,
        name: "",
        alias: "",
        gender: "",
        age: "",
        height: "",
        weight: "",
        build: "",
        complexion: "",
        hairColor: "",
        eyeColor: "",
        clothing: "",
        distinguishingFeature: "",
        language: "",
        knownAssociation: "",
        previousCriminal: "",
      },
    ],
    modeOfOperations: [
      {
        incident: reportId,
        weapons: "",
        make: "",
        model: "",
        color: "",
        licensePlateNumber: "",
        methodOfApproach: "",
        escapeRoute: "",
        toolUsed: "",
      },
    ],
    victims: [
      {
        incident: reportId,
        victimName: "",
        victimAge: "",
        victimGender: "",
        victimAddress: "",
        information: "",
        injuriesSustained: "",
        description: "",
        value: "",
        victimStatement: "",
      },
    ],
    witnesses: [
      {
        incident: reportId,
        witnessName: "",
        witnessAge: "",
        witnessGender: "",
        witnessAddress: "",
        witnessInformation: "",
        relationshipToVictim: "",
        witnessStatement: "",
      },
    ],
    cctvFootage: [],
    fingerprint: [],
    dnaSample: [],
    weaponRecovered: "",
    stolenPropertyRecovered: "",
    statementTaken: [],
    photosTaken: [],
    sketchCompositeImages: [],
    surveillanceConducted: [],
    suspectInterviews: "",
    forensicAnalysis: "",
    linkToOtherCrimes: "",


    // ...reportData,
    // officers: reportData?.officers || [
    //   {
    //     officerName: "",
    //     station: "",
    //     badgeNumber: "",
    //     reportingOfficerSignature: "",
    //   },
    // ],
    // incidentNumber: reportData?.incidentNumber || "",
    // incidentType: reportData?.incidentType || "",
    // dateTime: reportData?.dateTime || "",
    // location: reportData?.location || "",
    // briefDescription: reportData?.briefDescription || "",
  };
  // Define the initial form values and validation schema using Yup
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema:
      Yup.object({
        incidentNumber: Yup.string()
          .matches(
            /^[a-zA-Z0-9\s]*$/,
            "Only alphanumeric characters and spaces are allowed"
          )
          .required("Incident number is required"),
      }),
    // validationSchema:
    //Yup.object({
    //   officerName: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //        .required("Name is required"),
    //   station: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Station is required"),
    //   badgeNumber: Yup.string().matches(
    //     /^[a-zA-Z0-9\s]*$/,
    //     "Only alphabets and numbers are allowed"
    //   ),
    //   //.required("Badge Number is required"),
    //   date: Yup.string(),
    //   // .matches(/^\d{4}-\d{2}-\d{2}$/, "Date must be in the format YYYY-MM-DD")
    //   //.required("date is required"),
    //   incidentNumber: Yup.string().matches(
    //     /^[a-zA-Z0-9\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //        //.required("Name is required"),
    //   incidentType: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Incident Type is required"),
    //   location: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Location is required"),
    //   dateTime: Yup.string(),
    //   //.matches(/^\d{4}-\d{2}-\d{2}$/, "Date must be in the format YYYY-MM-DD")
    //   //.required("date is required"),
    //   briefDescription: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets  are allowed"
    //   ),
    //   //.required("Brief Description is required"),
    //   weatherCondition: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Weather condition is required"),
    //   lightingCondition: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Lighting condition is required"),
    //   presenseSecurityMeasures: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   // //.required("Presense Security Measures is required"),
    //   name: Yup.string().matches(/^[a-zA-Z\s]*$/, "Only alphabets are allowed"),
    //   //        //.required("Name is required"),
    //   alias: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Alias is required"),
    //   gender: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Gender is required"),
    //   age: Yup.string().matches(/^[0-9\s]*$/, "Only alphabets are allowed"),
    //   //.required("Age is required"),
    //   height: Yup.string().matches(/^[0-9]*$/, "Only alphabets are allowed"),
    //   //.required("Height is required"),
    //   weight: Yup.string(),
    //   //.matches(/^[0-9]*$/, "Only alphabets are allowed"),
    //   //.required("Weight is required"),
    //   build: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Build is required"),
    //   complexion: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Complexion is required"),
    //   hairColor: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Hair Color is required"),
    //   eyeColor: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Eye Color is required"),
    //   clothing: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Clothing is required"),
    //   distinguishingFeature: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Distinguishing Feature is required"),
    //   language: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Language is required"),
    //   knownAssociation: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Known Association is required"),
    //   previousCriminal: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   // //.required("Previous Criminal is required"),
    //   weapons: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Weapons is required"),
    //   make: Yup.string().matches(
    //     /^[a-zA-Z0-9\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Make is required"),
    //   model: Yup.string().matches(
    //     /^[a-zA-Z0-9\s]*$/,
    //     "Only alphanumeric characters are allowed"
    //   ),
    //   //.required("Model is required"),
    //   color: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Color is required"),
    //   licensePlateNumber: Yup.string().matches(
    //     /^[a-zA-Z0-9\s]*$/,
    //     "Only alphanumeric characters are allowed"
    //   ),
    //   //.required("License Plate Number is required"),
    //   methodOfApproach: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Method of Approach is required"),
    //   escapeRoute: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Escape Route is required"),
    //   toolUsed: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   // //.required("Tool Used is required"),
    //   victimName: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Victim Name is required"),
    //   victimAge: Yup.number()
    //     .positive("Victim Age must be a positive number")
    //     .integer("Victim Age must be an integer"),
    //   // .required("Victim Age is required"),
    //   victimGender: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Victim Gender is required"),
    //   victimAddress: Yup.string(),
    //   //.required("Victim Address is required"),
    //   information: Yup.string(),
    //   //.required("Information is required"),
    //   injuriesSustained: Yup.string(),
    //   //.required(
    //   //   "Injuries Sustained is required"
    //   // ),
    //   description: Yup.string(),
    //   //.required("Description is required"),
    //   value: Yup.number().positive("Value must be a positive number"),
    //   //.required("Value is required"),
    //   victimStatement: Yup.string(),
    //   // //.required("Victime Statement is required"),
    //   witnessName: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Witness Name is required"),
    //   witnessAge: Yup.number()
    //     .positive("Witness Age must be a positive number")
    //     .integer("Witness Age must be an integer"),
    //   //.required("Witness Age is required"),
    //   witnessGender: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Witness Gender is required"),
    //   witnessAddress: Yup.string(),
    //   //.required("Witness Address is required"),
    //   witnessInformation: Yup.string(),
    //   // .required(
    //   //   "Witness Information is required"
    //   // ),
    //   relationshipToVictim: Yup.string(),
    //   // .required(
    //   //   "Relationship to Victim is required"
    //   // ),
    //   witnessStatement: Yup.string(),
    //   // //.required("Witness Statement is required"),
    //   cctvFootage: Yup.array(),
    //   //.required("CCTV Footage is required"),
    //   fingerprint: Yup.array(),
    //   // //.required("Fingerprint is required"),
    //   dnaSample: Yup.array(),
    //   //.required("DNA Sample is required"),
    //   weaponRecovered: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   // //.required("Weapon Recovered is required"),
    //   stolenPropertyRecovered: Yup.string().matches(
    //     /^[a-zA-Z\s]*$/,
    //     "Only alphabets are allowed"
    //   ),
    //   //.required("Stolen Property Recovered is required"),
    //   statementTaken: Yup.array(),
    //   // //.required("Statement Taken is required"),
    //   photosTaken: Yup.array(),
    //   //.required("Photos Taken is required"),
    //   sketchCompositeImages: Yup.array(),
    //   //.required(
    //   //   "Sketch Composite Images is required"
    //   // ),
    //   surveillanceConducted: Yup.string(),
    //   // //.required(
    //   // //   "Surveillance Conducted is required"
    //   // // ),
    //   // suspectInterviews: Yup.string().matches(
    //   //   /^[a-zA-Z\s]*$/,
    //   //   "Only alphabets are allowed"
    //   // ),
    //   // //.required("Suspect Interviews is required"),
    //   // forensicAnalysis: Yup.string().matches(
    //   //   /^[a-zA-Z\s]*$/,
    //   //   "Only alphabets are allowed"
    //   // ),
    //   // // //.required("Forensic Analysis is required"),
    //   // linkToOtherCrimes: Yup.string().matches(
    //   //   /^[a-zA-Z\s]*$/,
    //   //   "Only alphabets are allowed"
    //   // ),
    //   //.required("Link to Other Crimes is required"),
    //   reportingOfficerSignature: Yup.mixed(),
    //   // .required(
    //   //   "Reporting Office Signature is required"
    //   // ),
    //   // signatureImage: Yup.mixed().required(
    //   //   "Reporting Office Signature is required"
    //   // ),
    //   pictures: Yup.array(),
    //   //.matches(/^[a-zA-Z\s]*$/, "Only image are allowed")
    //   //.required("Picture is required"),
    // }),
    onSubmit: async (values) => {
      console.log("values", values);
      try {
        // const editable = editable;
        const incident = {
          incidentNumber: values?.incidentNumber,
          incidentType: values?.incidentType,
          dateTime: values?.dateTime,
          location: values?.location,
          briefDescription: values?.briefDescription,
          weatherCondition: values?.weatherCondition,
          lightingCondition: values?.lightingCondition,
          presenseSecurityMeasures: values?.presenseSecurityMeasures,
          pictures: values?.pictures || [],
          ...(editable === 1 && { id: reportId })
        };
        const investigation = {
          incident: reportId,
          cctvFootage: values?.cctvFootage || [],
          fingerprint: values?.fingerprint || [],
          dnaSample: values?.dnaSample || [],
          weaponRecovered: values?.weaponRecovered,
          stolenPropertyRecovered: values?.stolenPropertyRecovered,
          statementTaken: values?.statementTaken || [],
          photosTaken: values?.photosTaken || [],
          sketchCompositeImages: values?.sketchCompositeImages || [],
          surveillanceConducted: values?.surveillanceConducted || [],
          suspectInterviews: values?.suspectInterviews,
          forensicAnalysis: values?.forensicAnalysis,
          linkToOtherCrimes: values?.linkToOtherCrimes,
        };

        const payload = {
          editable: editable,
          officers: values?.officers,
          incident: incident,
          suspects: values?.suspects,
          modeOfOperations: values?.modeOfOperations,
          investigation: investigation,
          victims: values?.victims,
          witnesses: values?.witnesses,
        };
        console.log("payload", payload);
        const response = await AddReportRecord(payload).unwrap();
        formik.resetForm();
        setSignatureImage(null);
        history.push("/incident_records");
        toast.success("Added successfully");
        // Handle additional logic for success
      } catch (err) {
        toast.error("Failed to add the report");
        console.error("Error response:", err);
        // Handle additional error logic
      }
    },
  });
  const handleFileUpload = (event, fieldName, index) => {
    const files = event.target.files;
    const imagesArray = Array.from(files);

    imagesArray.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        // Update signature image for the specific officer index directly
        if (fieldName === `officers.${index}.reportingOfficerSignature`) {
          setSignatureImage((prevState) => {
            const updatedSignatures = [...prevState];
            updatedSignatures[index] = base64String; // Set the image for the specific officer
            return updatedSignatures;
          });

          // Since this is a single signature field, set the base64 string directly
          formik.setFieldValue(fieldName, base64String);
        } else {
          // For other image fields, handle multiple files
          const currentFieldValue = formik.values[fieldName] || [];
          const updatedFieldValue = [...currentFieldValue, base64String];
          formik.setFieldValue(fieldName, updatedFieldValue);
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const handlePicturesUpload = (event) => {
    const files = event.target.files;
    const imagesArray = Array.from(files);

    const newImagesBase64 = [];

    imagesArray.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        newImagesBase64.push(base64String);

        if (newImagesBase64?.length === imagesArray.length) {
          formik.setFieldValue("pictures", [
            ...formik.values.pictures,
            ...newImagesBase64,
          ]);
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const handleRemoveImage = (indexToRemove) => {
    const updatedGallery = formik.values.pictures.filter(
      (_, index) => index !== indexToRemove
    );
    formik.setFieldValue("pictures", updatedGallery);
  };

  useEffect(() => {
    return () => {
      if (formik.values.pictures && formik.values.pictures?.fileURL) {
        // Revoke the object URL to avoid memory leaks
        URL.revokeObjectURL(formik.values.pictures.fileURL);
      }
    };
  }, [formik.values.pictures]);

  useEffect(() => {
    if (reportId) {
      getReportRecord(reportId);

    }

  }, [reportId, getReportRecord]);

  // Function to convert image URL to base64
  const getBase64FromUrl = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob); // Convert blob to base64
    });
  };

  useEffect(() => {
    if (reportData?.data && (editable === 1 || editable === 2)) {
      const { data } = reportData;

      // Set individual fields
      const date = new Date(data.dateTime);
      formik.setFieldValue("dateTime", date || "");
      formik.setFieldValue("incidentNumber", data.incidentNumber || "");
      formik.setFieldValue("incidentType", data.incidentType || "");
      formik.setFieldValue("location", data.location || "");
      formik.setFieldValue("briefDescription", data.briefDescription || "");
      formik.setFieldValue("weatherCondition", data.weatherCondition || "");
      formik.setFieldValue("lightingCondition", data.lightingCondition || "");
      formik.setFieldValue("presenseSecurityMeasures", data.presenseSecurityMeasures || "");
      formik.setFieldValue("pictures", data.pictures || []);

      // Assuming data has arrays for suspects, victims, etc., similar to initial values
      formik.setFieldValue("officers", data.officer || initialValues.officers);
      formik.setFieldValue("suspects", data.suspect || initialValues.suspects);
      formik.setFieldValue("modeOfOperations", data.mode_of_operation || initialValues.modeOfOperations);
      formik.setFieldValue("victims", data.victim || initialValues.victims);
      formik.setFieldValue("witnesses", data.witness || initialValues.witnesses);

      // Set other fields
      formik.setFieldValue("cctvFootage", data.cctvFootage || initialValues.cctvFootage);
      formik.setFieldValue("fingerprint", data.fingerprint || initialValues.fingerprint);
      formik.setFieldValue("dnaSample", data.dnaSample || initialValues.dnaSample);
      formik.setFieldValue("weaponRecovered", data.weaponRecovered || "");
      formik.setFieldValue("stolenPropertyRecovered", data.stolenPropertyRecovered || "");
      formik.setFieldValue("statementTaken", data.statementTaken || initialValues.statementTaken);
      formik.setFieldValue("photosTaken", data.photosTaken || initialValues.photosTaken);
      formik.setFieldValue("sketchCompositeImages", data.sketchCompositeImages || initialValues.sketchCompositeImages);
      formik.setFieldValue("surveillanceConducted", data.surveillanceConducted || initialValues.surveillanceConducted);
      formik.setFieldValue("suspectInterviews", data.suspectInterviews || "");
      formik.setFieldValue("forensicAnalysis", data.forensicAnalysis || "");
      formik.setFieldValue("linkToOtherCrimes", data.linkToOtherCrimes || "");



      // Inside your useEffect or wherever the signatures are fetched
      const signatures = {};
      let signatureUrl = [];
      data.officer.forEach(async (officer, index) => {
        const signatureUrl = appUrl.fileBaseUrl + officer.reportingOfficerSignature;

        // Convert signature image URL to base64
        const base64Signature = await getBase64FromUrl(signatureUrl);
        console.log("base64Signature", base64Signature)
        signatures[index] = base64Signature;

        // Update Formik field with the base64 signature
        formik.setFieldValue(`officers[${index}].reportingOfficerSignature`, base64Signature || "");
      });

      // Update state with base64 signatures
      setSignatureImages(signatures);
    }
  }, [reportData]);
  console.log("signatureImages", signatureImages)

  console.log("reportData", reportData)
  console.log("formik.incidentNumber", formik.values.incidentNumber);

  useEffect(() => {
    if (editable === 2) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [editable]);
  return (
    <>
      <div className="card">
        <div className="button-resp flex">
          <Button
            icon="pi pi-arrow-left"
            onClick={
              () => history.push("/incident_records")
              // window.history.back()
            }
            className="button-back"
            type="button"
          />
        </div>
        <div className="container mt-5">
          {isLoading ? (
            <CustomLoading isLoading={isLoading} />
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={formik.handleSubmit}
            >
              {({ values, handleChange, touched, errors }) => (
                <Form>
                  <Accordion multiple activeIndex={0}>
                    <AccordionTab header="Incident Details">
                      <div className="main-form">
                        <div className="p-fluid formgrid grid">
                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="incidentNumber">
                              Incident Number{" "}
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              id="incidentNumber"
                              name="incidentNumber"
                              placeholder="Enter Incident Number"
                              value={formik.values.incidentNumber}
                              onChange={formik.handleChange}
                              className={`form-control ${formik.touched.incidentNumber &&
                                formik.errors.incidentNumber
                                ? "is-invalid"
                                : ""
                                }`}
                              disabled={disable}
                            />
                            {formik.touched.incidentNumber &&
                              formik.errors.incidentNumber ? (
                              <div className="invalid-feedback">
                                {formik.errors.incidentNumber}
                              </div>
                            ) : null}
                          </div>
                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="dateTime">
                              Date Time{" "}
                              <span className="error required">*</span>
                            </label>
                            <Calendar
                              type="date"
                              id="dateTime"
                              showTime
                              showSeconds
                              placeholder="Select a Date and Time"
                              value={formik.values.dateTime}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "dateTime",
                                  e.target.value
                                );
                              }}
                              className={`form-control ${formik.touched.dateTime &&
                                formik.errors.dateTime
                                ? "is-invalid"
                                : ""
                                }`}
                              disabled={disable}
                            />
                            {formik.touched.dateTime &&
                              formik.errors.dateTime ? (
                              <div className="invalid-feedback">
                                {formik.errors.dateTime}
                              </div>
                            ) : null}
                          </div>
                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="incidentType">
                              Incident Type{" "}
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              id="incidentType"
                              name="incidentType"
                              placeholder="Enter Incident Type"
                              value={formik.values.incidentType}
                              onChange={formik.handleChange}
                              className={`form-control ${formik.touched.incidentType &&
                                formik.errors.incidentType
                                ? "is-invalid"
                                : ""
                                }`}
                              disabled={disable}
                            />
                            {formik.touched.incidentType &&
                              formik.errors.incidentType ? (
                              <div className="invalid-feedback">
                                {formik.errors.incidentType}
                              </div>
                            ) : null}
                          </div>
                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="location">
                              Location
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              id="location"
                              name="location"
                              placeholder="Enter Location Address"
                              value={formik.values.location}
                              onChange={formik.handleChange}
                              className={`form-control ${formik.touched.location &&
                                formik.errors.location
                                ? "is-invalid"
                                : ""
                                }`}
                              disabled={disable}
                            />
                            {formik.touched.location &&
                              formik.errors.location ? (
                              <div className="invalid-feedback">
                                {formik.errors.location}
                              </div>
                            ) : null}
                          </div>
                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="briefDescription">
                              Brief Description
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              id="briefDescription"
                              name="briefDescription"
                              placeholder="Enter Brief Description "
                              value={formik.values.briefDescription}
                              onChange={formik.handleChange}
                              className={`form-control ${formik.touched.briefDescription &&
                                formik.errors.briefDescription
                                ? "is-invalid"
                                : ""
                                }`}
                              disabled={disable}
                            />
                            {formik.touched.briefDescription &&
                              formik.errors.briefDescription ? (
                              <div className="invalid-feedback">
                                {formik.errors.briefDescription}
                              </div>
                            ) : null}
                          </div>
                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="weatherCondition">
                              Weather Condition{" "}
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              id="weatherCondition"
                              name="weatherCondition"
                              placeholder="Enter Weather Condition "
                              value={formik.values.weatherCondition}
                              onChange={formik.handleChange}
                              className={`form-control ${formik.touched.weatherCondition &&
                                formik.errors.weatherCondition
                                ? "is-invalid"
                                : ""
                                }`}
                              disabled={disable}
                            />
                            {formik.touched.weatherCondition &&
                              formik.errors.weatherCondition ? (
                              <div className="invalid-feedback">
                                {formik.errors.weatherCondition}
                              </div>
                            ) : null}
                          </div>
                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="lightingCondition">
                              Lighting Condition
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              id="lightingCondition"
                              name="lightingCondition"
                              placeholder="Enter Lighting Condition "
                              value={formik.values.lightingCondition}
                              onChange={formik.handleChange}
                              className={`form-control ${formik.touched.lightingCondition &&
                                formik.errors.lightingCondition
                                ? "is-invalid"
                                : ""
                                }`}
                              disabled={disable}
                            />
                            {formik.touched.lightingCondition &&
                              formik.errors.lightingCondition ? (
                              <div className="invalid-feedback">
                                {formik.errors.lightingCondition}
                              </div>
                            ) : null}
                          </div>
                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="presenseSecurityMeasures">
                              Presense Security Measures{" "}
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              id="presenseSecurityMeasures"
                              name="presenseSecurityMeasures"
                              placeholder="Enter Presense Security Measures "
                              value={formik.values.presenseSecurityMeasures}
                              onChange={formik.handleChange}
                              className={`form-control ${formik.touched.presenseSecurityMeasures &&
                                formik.errors.presenseSecurityMeasures
                                ? "is-invalid"
                                : ""
                                }`}
                              disabled={disable}
                            />
                            {formik.touched.presenseSecurityMeasures &&
                              formik.errors.presenseSecurityMeasures ? (
                              <div className="invalid-feedback">
                                {formik.errors.presenseSecurityMeasures}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {/* <div className="field md:col-12 lg:col-12 xl:col-12 responsive-col btn-save">
                          <div className="button-container">
                            <Button
                              className="p-button"
                              label="Save"
                              type="submit"
                            />
                          </div>
                        </div> */}
                      </div>{" "}
                    </AccordionTab>
                    <AccordionTab header="Officer Details">
                      <div className="main-form">
                        <FieldArray name="officers">
                          {({ push, remove }) => (
                            <>
                              {values?.officers?.map((officer, index) => (
                                <div
                                  key={index}
                                  className="index-form row-container"
                                >
                                  <div className="col-11 p-fluid formgrid grid">
                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`officers.${index}.officerName`}
                                      >
                                        Officer Name{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`officers.${index}.officerName`}
                                        name={`officers.${index}.officerName`}
                                        placeholder="Enter Officer Name"
                                        value={
                                          formik.values.officers?.[index]
                                            ?.officerName || ""
                                        }
                                        onChange={formik.handleChange(
                                          `officers.${index}.officerName`
                                        )}
                                        className={`form-control ${formik.touched.officers?.[index]
                                          ?.officerName &&
                                          formik.errors.officers?.[index]
                                            ?.officerName
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {formik.touched.officers?.[index]
                                        ?.officerName &&
                                        formik.errors.officers?.[index]
                                          ?.officerName ? (
                                        <div className="invalid-feedback">
                                          {
                                            formik.errors.officers[index]
                                              .officerName
                                          }
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`officers.${index}.station`}
                                      >
                                        Station{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`officers.${index}.station`}
                                        name={`officers.${index}.station`}
                                        placeholder="Enter Station"
                                        value={
                                          formik.values.officers?.[index]
                                            ?.station || ""
                                        }
                                        onChange={formik.handleChange(
                                          `officers.${index}.station`
                                        )}
                                        className={`form-control ${formik.touched.officers?.[index]
                                          ?.station &&
                                          formik.errors.officers?.[index]
                                            ?.station
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {formik.touched.officers?.[index]
                                        ?.station &&
                                        formik.errors.officers?.[index]
                                          ?.station ? (
                                        <div className="invalid-feedback">
                                          {
                                            formik.errors.officers[index]
                                              .station
                                          }
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`officers.${index}.badgeNumber`}
                                      >
                                        Badge Number{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`officers.${index}.badgeNumber`}
                                        name={`officers.${index}.badgeNumber`}
                                        placeholder="Enter Badge Number"
                                        value={
                                          formik.values.officers?.[index]
                                            ?.badgeNumber || ""
                                        }
                                        onChange={formik.handleChange(
                                          `officers.${index}.badgeNumber`
                                        )}
                                        className={`form-control ${formik.touched.officers?.[index]
                                          ?.badgeNumber &&
                                          formik.errors.officers?.[index]
                                            ?.badgeNumber
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {formik.touched.officers?.[index]
                                        ?.badgeNumber &&
                                        formik.errors.officers?.[index]
                                          ?.badgeNumber ? (
                                        <div className="invalid-feedback">
                                          {
                                            formik.errors.officers[index]
                                              .badgeNumber
                                          }
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="p-fluid formgrid grid">
                                      <div className="field md:col-12 lg:col-12 xl:col-12 responsive-col">
                                        <div className="field-signature">
                                          <div className="signature-button">
                                            <input
                                              type="file"
                                              ref={(el) => (signatureRef.current[index] = el)} // Assign ref
                                              style={{ display: "none" }} // Hide the input
                                              onChange={(e) => handleFileUpload(e, index)} // Handle file upload
                                              accept=".png, .jpg, .jpeg, .svg"
                                              disabled={disable}
                                            />
                                            {/* <input
                                              type="file"
                                              id={`officers.${index}.reportingOfficerSignature`}
                                              name={`officers.${index}.reportingOfficerSignature`}
                                              ref={(el) =>
                                              (signatureRef.current[index] =
                                                el)
                                              }
                                              style={{ display: "none" }}
                                              onChange={(event) =>
                                                handleFileUpload(
                                                  event,
                                                  `officers.${index}.reportingOfficerSignature`,
                                                  index
                                                )
                                              }
                                              accept=".png, .jpg, .jpeg, .svg"
                                            /> */}

                                            <Button
                                              type="button"
                                              className="p-signature-button"
                                              onClick={() => {
                                                handleButtonClick(index);
                                              }}
                                              disabled={disable}
                                            >
                                              Upload
                                            </Button>
                                            <Button
                                              className="p-signature-button"
                                              type="button"
                                              onClick={() =>
                                                handleDrawClick(index)
                                              }
                                              disabled={disable}
                                            >
                                              {isDrawing[index]
                                                ? "Cancel"
                                                : "Draw"}
                                            </Button>
                                          </div>

                                          {isDrawing[index] && (
                                            <div className="field">
                                              <SignatureCanvas
                                                ref={(el) =>
                                                (signatureRef.current[index] =
                                                  el)
                                                }
                                                penColor="black"
                                                canvasProps={{
                                                  width: 500,
                                                  height: 200,
                                                  className: "signature-canvas",
                                                }}

                                              />
                                              <div className="signature-button">
                                                <Button
                                                  className="p-signature-button"
                                                  type="button"
                                                  onClick={() => {
                                                    handleSaveSignature(index);
                                                  }}
                                                  disabled={disable}
                                                >
                                                  Save
                                                </Button>
                                                <Button
                                                  className="p-signature-button"
                                                  type="button"
                                                  onClick={() => {
                                                    handleClearSignature(index);
                                                  }}
                                                  disabled={disable}
                                                >
                                                  Clear
                                                </Button>
                                              </div>
                                              {signatureImages[index] && (
                                                <div className="signature-image">
                                                  <img
                                                    src={signatureImages[index]}
                                                    alt={`Officer ${index} Signature`}
                                                    style={{ width: '500px', height: '200px' }} // Adjust size as needed
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          )}

                                          {signatureImage &&
                                            signatureImage.length > 0 &&
                                            signatureImage[index] && (
                                              <div className="signature-image">
                                                <img
                                                  className="s-image"
                                                  src={signatureImage[index]}
                                                  alt="Signature"
                                                />
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>

                                    {/* <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label htmlFor={`officers.${index}.date`}>
                                        Date{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        type="date"
                                        id={`officers.${index}.date`}
                                        name={`officers.${index}.date`}
                                        placeholder="Select Date"
                                        value={
                                          formik.values.officers?.[index]
                                            ?.date || ""
                                        }
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            `officers.${index}.date`,
                                            e.target.value
                                          );
                                        }}
                                        className={`form-control ${
                                          formik.touched.officers?.[index]
                                            ?.date &&
                                          formik.errors.officers?.[index]?.date
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                      />
                                      {formik.touched.officers?.[index]?.date &&
                                      formik.errors.officers?.[index]?.date ? (
                                        <div className="invalid-feedback">
                                          {formik.errors.officers[index].date}
                                        </div>
                                      ) : null}
                                    </div> */}
                                  </div>
                                  <div className="col-1 icon-buttons">
                                    <button
                                      className="inner-button add-button"
                                      type="button"
                                      onClick={() => push({})} // Add a new officers
                                      disabled={disable}
                                    >
                                      <i className="pi pi-plus"></i>{" "}
                                    </button>
                                    <button
                                      className="inner-button remove-button"
                                      type="button"
                                      onClick={() => {
                                        if (index > 0) {
                                          remove(index); // Remove the current officer only if index is greater than 0
                                        }
                                      }} // Remove the current officer
                                      disabled={disable}
                                    >
                                      <i className="pi pi-minus"></i>{" "}
                                      {/* PrimeReact Icon */}
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </div>
                      {/* <div className="field md:col-12 lg:col-12 xl:col-12 responsive-col btn-save">
                          <div className="button-container">
                            <Button
                              className="p-button"
                              label="Save"
                              type="submit"
                            />
                          </div>
                        </div> */}
                    </AccordionTab>
                    <AccordionTab header="Suspect Details">
                      <div className="main-form">
                        <FieldArray name="suspects">
                          {({ push, remove }) => (
                            <>
                              {values?.suspects?.map((suspect, index) => (
                                <div
                                  className="index-form row-container"
                                  key={index}
                                >
                                  <div className="col-11 p-fluid formgrid grid">
                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label htmlFor={`suspects.${index}.name`}>
                                        Name{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`suspects.${index}.name`}
                                        name={`suspects.${index}.name`}
                                        placeholder="Enter Name"
                                        value={
                                          formik.values.suspects[index]?.name ||
                                          ""
                                        }
                                        onChange={formik.handleChange(
                                          `suspects.${index}.name`
                                        )}
                                        className={`form-control ${formik.touched.suspects?.[index]
                                          ?.name &&
                                          formik.errors.suspects?.[index]?.name
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {formik.touched.suspects?.[index]?.name &&
                                        formik.errors.suspects?.[index]?.name ? (
                                        <div className="invalid-feedback">
                                          {formik.errors.suspects[index].name}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`suspects.${index}.alias`}
                                      >
                                        Alias{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`suspects.${index}.alias`}
                                        name={`suspects.${index}.alias`}
                                        placeholder="Enter Alias"
                                        value={
                                          formik.values.suspects[index]
                                            ?.alias || ""
                                        }
                                        onChange={formik.handleChange(
                                          `suspects.${index}.alias`
                                        )}
                                        className={`form-control ${formik.touched.suspects?.[index]
                                          ?.alias &&
                                          formik.errors.suspects?.[index]?.alias
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {formik.touched.suspects?.[index]
                                        ?.alias &&
                                        formik.errors.suspects?.[index]?.alias ? (
                                        <div className="invalid-feedback">
                                          {formik.errors.suspects[index].alias}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label>
                                        Gender{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <div className="form-check-radio">
                                        <div className="form-check">
                                          <input
                                            type="radio"
                                            id={`suspects.${index}.gender.Male`}
                                            name={`suspects.${index}.gender`}
                                            value="Male"
                                            onChange={formik.handleChange(
                                              `suspects.${index}.gender`
                                            )}
                                            className={`form-check-input ${formik.touched.suspects?.[index]
                                              ?.gender &&
                                              formik.errors.suspects?.[index]
                                                ?.gender
                                              ? "is-invalid"
                                              : ""
                                              }`}
                                            checked={
                                              formik.values.suspects[index]
                                                ?.gender === "Male"
                                            }
                                            disabled={disable}
                                          />
                                          <label
                                            htmlFor={`suspects.${index}.gender.Male`}
                                            className="ml-2"
                                          >
                                            Male
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <input
                                            type="radio"
                                            id={`suspects.${index}.gender.Female`}
                                            name={`suspects.${index}.gender`}
                                            value="Female"
                                            onChange={formik.handleChange(
                                              `suspects.${index}.gender`
                                            )}
                                            className={`form-check-input ${formik.touched.suspects?.[index]
                                              ?.gender &&
                                              formik.errors.suspects?.[index]
                                                ?.gender
                                              ? "is-invalid"
                                              : ""
                                              }`}
                                            checked={
                                              formik.values.suspects[index]
                                                ?.gender === "Female"
                                            }
                                            disabled={disable}
                                          />
                                          <label
                                            htmlFor={`suspects.${index}.gender.Female`}
                                            className="ml-2"
                                          >
                                            Female
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <input
                                            type="radio"
                                            id={`suspects.${index}.gender.Other`}
                                            name={`suspects.${index}.gender`}
                                            value="Other"
                                            onChange={formik.handleChange(
                                              `suspects.${index}.gender`
                                            )}
                                            className={`form-check-input ${formik.touched.suspects?.[index]
                                              ?.gender &&
                                              formik.errors.suspects?.[index]
                                                ?.gender
                                              ? "is-invalid"
                                              : ""
                                              }`}
                                            checked={
                                              formik.values.suspects[index]
                                                ?.gender === "Other"
                                            }
                                            disabled={disable}
                                          />
                                          <label
                                            htmlFor={`suspects.${index}.gender.Other`}
                                            className="form-check-label"
                                          >
                                            Other
                                          </label>
                                        </div>
                                      </div>
                                      {formik.touched.suspects?.[index]
                                        ?.gender &&
                                        formik.errors.suspects?.[index]
                                          ?.gender ? (
                                        <div className="invalid-feedback">
                                          {formik.errors.suspects[index].gender}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label htmlFor={`suspects.${index}.age`}>
                                        Age{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`suspects.${index}.age`}
                                        name={`suspects.${index}.age`}
                                        placeholder="Enter Age"
                                        value={
                                          formik.values.suspects[index]?.age ||
                                          ""
                                        }
                                        onChange={formik.handleChange(
                                          `suspects.${index}.age`
                                        )}
                                        className={`form-control ${formik.touched.suspects?.[index]
                                          ?.age &&
                                          formik.errors.suspects?.[index]?.age
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {formik.touched.suspects?.[index]?.age &&
                                        formik.errors.suspects?.[index]?.age ? (
                                        <div className="invalid-feedback">
                                          {formik.errors.suspects[index].age}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`suspects.${index}.height`}
                                      >
                                        Height{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`suspects.${index}.height`}
                                        name={`suspects.${index}.height`}
                                        placeholder="Enter Height"
                                        value={
                                          formik.values.suspects[index]
                                            ?.height || ""
                                        }
                                        onChange={formik.handleChange}
                                        className={`form-control ${formik.touched.suspects?.[index]
                                          ?.height &&
                                          formik.errors.suspects?.[index]
                                            ?.height
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {formik.touched.suspects?.[index]
                                        ?.height &&
                                        formik.errors.suspects?.[index]
                                          ?.height ? (
                                        <div className="invalid-feedback">
                                          {formik.errors.suspects[index].height}
                                        </div>
                                      ) : null}
                                    </div>

                                    {/* Repeat the same structure for the remaining fields: weight, build, complexion, etc. */}

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`suspects.${index}.weight`}
                                      >
                                        Weight{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`suspects.${index}.weight`}
                                        name={`suspects.${index}.weight`}
                                        placeholder="Enter Weight"
                                        value={
                                          formik.values.suspects[index]
                                            ?.weight || ""
                                        }
                                        onChange={formik.handleChange(
                                          `suspects.${index}.weight`
                                        )}
                                        className={`form-control ${formik.touched.suspects?.[index]
                                          ?.weight &&
                                          formik.errors.suspects?.[index]
                                            ?.weight
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {formik.touched.suspects?.[index]
                                        ?.weight &&
                                        formik.errors.suspects?.[index]
                                          ?.weight ? (
                                        <div className="invalid-feedback">
                                          {formik.errors.suspects[index].weight}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`suspects.${index}.build`}
                                      >
                                        Build{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`suspects.${index}.build`}
                                        name={`suspects.${index}.build`}
                                        placeholder="Enter Build"
                                        value={
                                          formik.values.suspects?.[index]
                                            ?.build || ""
                                        }
                                        onChange={formik.handleChange(
                                          `suspects.${index}.build`
                                        )}
                                        className={`form-control ${formik.touched.suspects?.[index]
                                          ?.build &&
                                          formik.errors.suspects?.[index]?.build
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {formik.touched.suspects?.[index]
                                        ?.build &&
                                        formik.errors.suspects?.[index]?.build ? (
                                        <div className="invalid-feedback">
                                          {formik.errors.suspects[index]?.build}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`suspects.${index}.complexion`}
                                      >
                                        Complexion{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`suspects.${index}.complexion`}
                                        name={`suspects.${index}.complexion`}
                                        placeholder="Enter Complexion"
                                        value={
                                          formik.values.suspects?.[index]
                                            ?.complexion || ""
                                        }
                                        onChange={formik.handleChange}
                                        className={`form-control ${formik.touched.suspects?.[index]
                                          ?.complexion &&
                                          formik.errors.suspects?.[index]
                                            ?.complexion
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {formik.touched.suspects?.[index]
                                        ?.complexion &&
                                        formik.errors.suspects?.[index]
                                          ?.complexion ? (
                                        <div className="invalid-feedback">
                                          {
                                            formik.errors.suspects[index]
                                              ?.complexion
                                          }
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`suspects.${index}.hairColor`}
                                      >
                                        Hair Color{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`suspects.${index}.hairColor`}
                                        name={`suspects.${index}.hairColor`}
                                        placeholder="Enter Hair Color"
                                        value={
                                          formik.values.suspects?.[index]
                                            ?.hairColor || ""
                                        }
                                        onChange={formik.handleChange(
                                          `suspects.${index}.hairColor`
                                        )}
                                        className={`form-control ${formik.touched.suspects?.[index]
                                          ?.hairColor &&
                                          formik.errors.suspects?.[index]
                                            ?.hairColor
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {formik.touched.suspects?.[index]
                                        ?.hairColor &&
                                        formik.errors.suspects?.[index]
                                          ?.hairColor ? (
                                        <div className="invalid-feedback">
                                          {
                                            formik.errors.suspects[index]
                                              ?.hairColor
                                          }
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`suspects.${index}.eyeColor`}
                                      >
                                        Eye Color{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`suspects.${index}.eyeColor`}
                                        name={`suspects.${index}.eyeColor`}
                                        placeholder="Enter Eye Color"
                                        value={
                                          formik.values.suspects?.[index]
                                            ?.eyeColor || ""
                                        }
                                        onChange={formik.handleChange(
                                          `suspects.${index}.eyeColor`
                                        )}
                                        className={`form-control ${formik.touched.suspects?.[index]
                                          ?.eyeColor &&
                                          formik.errors.suspects?.[index]
                                            ?.eyeColor
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {formik.touched.suspects?.[index]
                                        ?.eyeColor &&
                                        formik.errors.suspects?.[index]
                                          ?.eyeColor ? (
                                        <div className="invalid-feedback">
                                          {
                                            formik.errors.suspects[index]
                                              ?.eyeColor
                                          }
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`suspects.${index}.clothing`}
                                      >
                                        Clothing{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`suspects.${index}.clothing`}
                                        name={`suspects.${index}.clothing`}
                                        placeholder="Enter Clothing"
                                        value={
                                          formik.values.suspects?.[index]
                                            ?.clothing || ""
                                        }
                                        onChange={formik.handleChange(
                                          `suspects.${index}.clothing`
                                        )}
                                        className={`form-control ${formik.touched.suspects?.[index]
                                          ?.clothing &&
                                          formik.errors.suspects?.[index]
                                            ?.clothing
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {formik.touched.suspects?.[index]
                                        ?.clothing &&
                                        formik.errors.suspects?.[index]
                                          ?.clothing ? (
                                        <div className="invalid-feedback">
                                          {
                                            formik.errors.suspects[index]
                                              ?.clothing
                                          }
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`suspects.${index}.distinguishingFeature`}
                                      >
                                        Distinguishing Feature{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`suspects.${index}.distinguishingFeature`}
                                        name={`suspects.${index}.distinguishingFeature`}
                                        placeholder="Enter Distinguishing Feature"
                                        value={
                                          formik.values.suspects?.[index]
                                            ?.distinguishingFeature || ""
                                        }
                                        onChange={formik.handleChange(
                                          `suspects.${index}.distinguishingFeature`
                                        )}
                                        className={`form-control ${formik.touched.suspects?.[index]
                                          ?.distinguishingFeature &&
                                          formik.errors.suspects?.[index]
                                            ?.distinguishingFeature
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {formik.touched.suspects?.[index]
                                        ?.distinguishingFeature &&
                                        formik.errors.suspects?.[index]
                                          ?.distinguishingFeature ? (
                                        <div className="invalid-feedback">
                                          {
                                            formik.errors.suspects[index]
                                              ?.distinguishingFeature
                                          }
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`suspects.${index}.language`}
                                      >
                                        Language/Accent{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`suspects.${index}.language`}
                                        name={`suspects.${index}.language`}
                                        placeholder="Enter Language/Accent"
                                        value={
                                          formik.values.suspects?.[index]
                                            ?.language || ""
                                        }
                                        onChange={formik.handleChange(
                                          `suspects.${index}.language`
                                        )}
                                        className={`form-control ${formik.touched.suspects?.[index]
                                          ?.language &&
                                          formik.errors.suspects?.[index]
                                            ?.language
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {formik.touched.suspects?.[index]
                                        ?.language &&
                                        formik.errors.suspects?.[index]
                                          ?.language ? (
                                        <div className="invalid-feedback">
                                          {
                                            formik.errors.suspects[index]
                                              ?.language
                                          }
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`suspects.${index}.knownAssociation`}
                                      >
                                        Known Association{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`suspects.${index}.knownAssociation`}
                                        name={`suspects.${index}.knownAssociation`}
                                        placeholder="Enter Known Association"
                                        value={
                                          formik.values.suspects?.[index]
                                            ?.knownAssociation || ""
                                        }
                                        onChange={formik.handleChange(
                                          `suspects.${index}.knownAssociation`
                                        )}
                                        className={`form-control ${formik.touched.suspects?.[index]
                                          ?.knownAssociation &&
                                          formik.errors.suspects?.[index]
                                            ?.knownAssociation
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {formik.touched.suspects?.[index]
                                        ?.knownAssociation &&
                                        formik.errors.suspects?.[index]
                                          ?.knownAssociation ? (
                                        <div className="invalid-feedback">
                                          {
                                            formik.errors.suspects[index]
                                              ?.knownAssociation
                                          }
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`suspects.${index}.previousCriminal`}
                                      >
                                        Previous Criminal Activities{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`suspects.${index}.previousCriminal`}
                                        name={`suspects.${index}.previousCriminal`}
                                        placeholder="Enter Previous Criminal Activities"
                                        value={
                                          formik.values.suspects?.[index]
                                            ?.previousCriminal || ""
                                        }
                                        onChange={formik.handleChange(
                                          `suspects.${index}.previousCriminal`
                                        )}
                                        className={`form-control ${formik.touched.suspects?.[index]
                                          ?.previousCriminal &&
                                          formik.errors.suspects?.[index]
                                            ?.previousCriminal
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {formik.touched.suspects?.[index]
                                        ?.previousCriminal &&
                                        formik.errors.suspects?.[index]
                                          ?.previousCriminal ? (
                                        <div className="invalid-feedback">
                                          {
                                            formik.errors.suspects[index]
                                              ?.previousCriminal
                                          }
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-1 icon-buttons">
                                    <button
                                      className="inner-button add-button"
                                      type="button"
                                      onClick={() => push({})} // Add a new officer
                                      disabled={disable}
                                    >
                                      <i className="pi pi-plus"></i>{" "}
                                    </button>
                                    <button
                                      className="inner-button remove-button"
                                      type="button"
                                      onClick={() => {
                                        if (index > 0) {
                                          remove(index); // Remove the current officer only if index is greater than 0
                                        }
                                      }} // Remove the current officer
                                      disabled={disable}
                                    >
                                      <i className="pi pi-minus"></i>{" "}
                                      {/* PrimeReact Icon */}
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </div>{" "}
                    </AccordionTab>
                    <AccordionTab header="Mode of Operation">
                      <div className="main-form">
                        <FieldArray name="suspects">
                          {({ push, remove }) => (
                            <>
                              {values?.modeOfOperations?.map(
                                (modeOfOperation, index) => (
                                  <div
                                    className="index-form row-container"
                                    key={index}
                                  >
                                    <div className="col-11 p-fluid formgrid grid">
                                      <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                        <label
                                          htmlFor={`modeOfOperations.${index}.weapons`}
                                        >
                                          Weapons{" "}
                                          <span className="error required">
                                            *
                                          </span>
                                        </label>
                                        <InputText
                                          id={`modeOfOperations.${index}.weapons`}
                                          name={`modeOfOperations.${index}.weapons`}
                                          placeholder="Enter Weapons"
                                          value={
                                            formik.values.modeOfOperations?.[
                                              index
                                            ]?.weapons || ""
                                          }
                                          onChange={formik.handleChange(
                                            `modeOfOperations.${index}.weapons`
                                          )}
                                          className={`form-control ${formik.touched.modeOfOperations?.[
                                            index
                                          ]?.weapons &&
                                            formik.errors.modeOfOperations?.[
                                              index
                                            ]?.weapons
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          disabled={disable}
                                        />
                                        {formik.touched.modeOfOperations?.[
                                          index
                                        ]?.weapons &&
                                          formik.errors.modeOfOperations?.[index]
                                            ?.weapons ? (
                                          <div className="invalid-feedback">
                                            {
                                              formik.errors.modeOfOperations?.[
                                                index
                                              ]?.weapons
                                            }
                                          </div>
                                        ) : null}
                                      </div>

                                      {/* Make */}
                                      <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                        <label
                                          htmlFor={`modeOfOperations.${index}.make`}
                                        >
                                          Make{" "}
                                          <span className="error required">
                                            *
                                          </span>
                                        </label>
                                        <InputText
                                          id={`modeOfOperations.${index}.make`}
                                          name={`modeOfOperations.${index}.make`}
                                          placeholder="Enter Make"
                                          value={
                                            formik.values.modeOfOperations?.[
                                              index
                                            ]?.make || ""
                                          }
                                          onChange={formik.handleChange(
                                            `modeOfOperations.${index}.make`
                                          )}
                                          className={`form-control ${formik.touched.modeOfOperations?.[
                                            index
                                          ]?.make &&
                                            formik.errors.modeOfOperations?.[
                                              index
                                            ]?.make
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          disabled={disable}
                                        />
                                        {formik.touched.modeOfOperations?.[
                                          index
                                        ]?.make &&
                                          formik.errors.modeOfOperations?.[index]
                                            ?.make ? (
                                          <div className="invalid-feedback">
                                            {
                                              formik.errors.modeOfOperations?.[
                                                index
                                              ]?.make
                                            }
                                          </div>
                                        ) : null}
                                      </div>

                                      {/* Model */}
                                      <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                        <label
                                          htmlFor={`modeOfOperations.${index}.model`}
                                        >
                                          Model{" "}
                                          <span className="error required">
                                            *
                                          </span>
                                        </label>
                                        <InputText
                                          id={`modeOfOperations.${index}.model`}
                                          name={`modeOfOperations.${index}.model`}
                                          placeholder="Enter Model"
                                          value={
                                            formik.values.modeOfOperations?.[
                                              index
                                            ]?.model || ""
                                          }
                                          onChange={formik.handleChange(
                                            `modeOfOperations.${index}.model`
                                          )}
                                          className={`form-control ${formik.touched.modeOfOperations?.[
                                            index
                                          ]?.model &&
                                            formik.errors.modeOfOperations?.[
                                              index
                                            ]?.model
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          disabled={disable}
                                        />
                                        {formik.touched.modeOfOperations?.[
                                          index
                                        ]?.model &&
                                          formik.errors.modeOfOperations?.[index]
                                            ?.model ? (
                                          <div className="invalid-feedback">
                                            {
                                              formik.errors.modeOfOperations?.[
                                                index
                                              ]?.model
                                            }
                                          </div>
                                        ) : null}
                                      </div>

                                      {/* Color */}
                                      <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                        <label
                                          htmlFor={`modeOfOperations.${index}.color`}
                                        >
                                          Color{" "}
                                          <span className="error required">
                                            *
                                          </span>
                                        </label>
                                        <InputText
                                          id={`modeOfOperations.${index}.color`}
                                          name={`modeOfOperations.${index}.color`}
                                          placeholder="Enter Color"
                                          value={
                                            formik.values.modeOfOperations?.[
                                              index
                                            ]?.color || ""
                                          }
                                          onChange={formik.handleChange(
                                            `modeOfOperations.${index}.color`
                                          )}
                                          className={`form-control ${formik.touched.modeOfOperations?.[
                                            index
                                          ]?.color &&
                                            formik.errors.modeOfOperations?.[
                                              index
                                            ]?.color
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          disabled={disable}
                                        />
                                        {formik.touched.modeOfOperations?.[
                                          index
                                        ]?.color &&
                                          formik.errors.modeOfOperations?.[index]
                                            ?.color ? (
                                          <div className="invalid-feedback">
                                            {
                                              formik.errors.modeOfOperations?.[
                                                index
                                              ]?.color
                                            }
                                          </div>
                                        ) : null}
                                      </div>

                                      {/* License Plate Number */}
                                      <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                        <label
                                          htmlFor={`modeOfOperations.${index}.licensePlateNumber`}
                                        >
                                          License Plate Number{" "}
                                          <span className="error required">
                                            *
                                          </span>
                                        </label>
                                        <InputText
                                          id={`modeOfOperations.${index}.licensePlateNumber`}
                                          name={`modeOfOperations.${index}.licensePlateNumber`}
                                          placeholder="Enter License Plate Number"
                                          value={
                                            formik.values.modeOfOperations?.[
                                              index
                                            ]?.licensePlateNumber || ""
                                          }
                                          onChange={formik.handleChange(
                                            `modeOfOperations.${index}.licensePlateNumber`
                                          )}
                                          className={`form-control ${formik.touched.modeOfOperations?.[
                                            index
                                          ]?.licensePlateNumber &&
                                            formik.errors.modeOfOperations?.[
                                              index
                                            ]?.licensePlateNumber
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          disabled={disable}
                                        />
                                        {formik.touched.modeOfOperations?.[
                                          index
                                        ]?.licensePlateNumber &&
                                          formik.errors.modeOfOperations?.[index]
                                            ?.licensePlateNumber ? (
                                          <div className="invalid-feedback">
                                            {
                                              formik.errors.modeOfOperations?.[
                                                index
                                              ]?.licensePlateNumber
                                            }
                                          </div>
                                        ) : null}
                                      </div>

                                      {/* Method of Approach */}
                                      <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                        <label
                                          htmlFor={`modeOfOperations.${index}.methodOfApproach`}
                                        >
                                          Method of Approach{" "}
                                          <span className="error required">
                                            *
                                          </span>
                                        </label>
                                        <InputText
                                          id={`modeOfOperations.${index}.methodOfApproach`}
                                          name={`modeOfOperations.${index}.methodOfApproach`}
                                          placeholder="Enter Method of Approach"
                                          value={
                                            formik.values.modeOfOperations?.[
                                              index
                                            ]?.methodOfApproach || ""
                                          }
                                          onChange={formik.handleChange(
                                            `modeOfOperations.${index}.methodOfApproach`
                                          )}
                                          className={`form-control ${formik.touched.modeOfOperations?.[
                                            index
                                          ]?.methodOfApproach &&
                                            formik.errors.modeOfOperations?.[
                                              index
                                            ]?.methodOfApproach
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          disabled={disable}
                                        />
                                        {formik.touched.modeOfOperations?.[
                                          index
                                        ]?.methodOfApproach &&
                                          formik.errors.modeOfOperations?.[index]
                                            ?.methodOfApproach ? (
                                          <div className="invalid-feedback">
                                            {
                                              formik.errors.modeOfOperations?.[
                                                index
                                              ]?.methodOfApproach
                                            }
                                          </div>
                                        ) : null}
                                      </div>

                                      {/* Escape Route */}
                                      <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                        <label
                                          htmlFor={`modeOfOperations.${index}.escapeRoute`}
                                        >
                                          Escape Route{" "}
                                          <span className="error required">
                                            *
                                          </span>
                                        </label>
                                        <InputText
                                          id={`modeOfOperations.${index}.escapeRoute`}
                                          name={`modeOfOperations.${index}.escapeRoute`}
                                          placeholder="Enter Escape Route"
                                          value={
                                            formik.values.modeOfOperations?.[
                                              index
                                            ]?.escapeRoute || ""
                                          }
                                          onChange={formik.handleChange(
                                            `modeOfOperations.${index}.escapeRoute`
                                          )}
                                          className={`form-control ${formik.touched.modeOfOperations?.[
                                            index
                                          ]?.escapeRoute &&
                                            formik.errors.modeOfOperations?.[
                                              index
                                            ]?.escapeRoute
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          disabled={disable}
                                        />
                                        {formik.touched.modeOfOperations?.[
                                          index
                                        ]?.escapeRoute &&
                                          formik.errors.modeOfOperations?.[index]
                                            ?.escapeRoute ? (
                                          <div className="invalid-feedback">
                                            {
                                              formik.errors.modeOfOperations?.[
                                                index
                                              ]?.escapeRoute
                                            }
                                          </div>
                                        ) : null}
                                      </div>

                                      {/* Tool Used */}
                                      <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                        <label
                                          htmlFor={`modeOfOperations.${index}.toolUsed`}
                                        >
                                          Tool Used{" "}
                                          <span className="error required">
                                            *
                                          </span>
                                        </label>
                                        <InputText
                                          id={`modeOfOperations.${index}.toolUsed`}
                                          name={`modeOfOperations.${index}.toolUsed`}
                                          placeholder="Enter Tool Used"
                                          value={
                                            formik.values.modeOfOperations?.[
                                              index
                                            ]?.toolUsed || ""
                                          }
                                          onChange={formik.handleChange(
                                            `modeOfOperations.${index}.toolUsed`
                                          )}
                                          className={`form-control ${formik.touched.modeOfOperations?.[
                                            index
                                          ]?.toolUsed &&
                                            formik.errors.modeOfOperations?.[
                                              index
                                            ]?.toolUsed
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          disabled={disable}
                                        />
                                        {formik.touched.modeOfOperations?.[
                                          index
                                        ]?.toolUsed &&
                                          formik.errors.modeOfOperations?.[index]
                                            ?.toolUsed ? (
                                          <div className="invalid-feedback">
                                            {
                                              formik.errors.modeOfOperations?.[
                                                index
                                              ]?.toolUsed
                                            }
                                          </div>
                                        ) : null}
                                      </div>

                                      {/* Remove Suspect Button */}
                                    </div>
                                    <div className="col-1 icon-buttons">
                                      <button
                                        className="inner-button add-button"
                                        type="button"
                                        onClick={() => push({})} // Add a new officer
                                        disabled={disable}
                                      >
                                        <i className="pi pi-plus"></i>{" "}
                                      </button>
                                      <button
                                        className="inner-button remove-button"
                                        type="button"
                                        onClick={() => {
                                          if (index > 0) {
                                            remove(index); // Remove the current officer only if index is greater than 0
                                          }
                                        }} // Remove the current officer
                                        disabled={disable}
                                      >
                                        <i className="pi pi-minus"></i>{" "}
                                        {/* PrimeReact Icon */}
                                      </button>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          )}
                        </FieldArray>
                      </div>
                    </AccordionTab>

                    <AccordionTab header="Victim Details">
                      <div className="main-form">
                        <FieldArray name="victims">
                          {({ push, remove }) => (
                            <>
                              {values?.victims?.map((victim, index) => (
                                <div
                                  key={index}
                                  className="index-form row-container"
                                >
                                  <div className="col-11 p-fluid formgrid grid">
                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`victims.${index}.victimName`}
                                      >
                                        Victim Name{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`victims.${index}.victimName`}
                                        name={`victims.${index}.victimName`}
                                        placeholder="Enter Victim Name"
                                        value={
                                          formik.values.victims?.[index]
                                            ?.victimName || ""
                                        }
                                        onChange={formik.handleChange(
                                          `victims.${index}.victimName`
                                        )}
                                        className={`form-control ${touched.victims?.[index]
                                          ?.victimName &&
                                          errors.victims?.[index]?.victimName
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {touched.victims?.[index]?.victimName &&
                                        errors.victims?.[index]?.victimName ? (
                                        <div className="invalid-feedback">
                                          {errors.victims[index].victimName}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`victims.${index}.victimAge`}
                                      >
                                        Victim Age{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`victims.${index}.victimAge`}
                                        name={`victims.${index}.victimAge`}
                                        placeholder="Enter Victim Age"
                                        value={
                                          formik.values.victims?.[index]
                                            ?.victimAge || ""
                                        }
                                        onChange={formik.handleChange(
                                          `victims.${index}.victimAge`
                                        )}
                                        className={`form-control ${touched.victims?.[index]?.victimAge &&
                                          errors.victims?.[index]?.victimAge
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {touched.victims?.[index]?.victimAge &&
                                        errors.victims?.[index]?.victimAge ? (
                                        <div className="invalid-feedback">
                                          {errors.victims[index].victimAge}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label>
                                        Victim Gender{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <div className="form-check-radio">
                                        <div className="form-check">
                                          <input
                                            type="radio"
                                            id={`victims.${index}.male`}
                                            name={`victims.${index}.victimGender`}
                                            value="Male"
                                            checked={
                                              formik.values.victims?.[index]
                                                ?.victimGender === "Male"
                                            }
                                            onChange={formik.handleChange(
                                              `victims.${index}.victimGender`
                                            )}
                                            className={`form-check-input ${touched.victims?.[index]
                                              ?.victimGender &&
                                              errors.victims?.[index]
                                                ?.victimGender
                                              ? "is-invalid"
                                              : ""
                                              }`}
                                            disabled={disable}
                                          />
                                          <label
                                            htmlFor={`victims.${index}.male`}
                                            className="form-check-label"
                                          >
                                            Male
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <input
                                            type="radio"
                                            id={`victims.${index}.female`}
                                            name={`victims.${index}.victimGender`}
                                            value="Female"
                                            checked={
                                              formik.values.victims?.[index]
                                                ?.victimGender === "Female"
                                            }
                                            onChange={formik.handleChange(
                                              `victims.${index}.victimGender`
                                            )}
                                            className={`form-check-input ${touched.victims?.[index]
                                              ?.victimGender &&
                                              errors.victims?.[index]
                                                ?.victimGender
                                              ? "is-invalid"
                                              : ""
                                              }`}
                                            disabled={disable}
                                          />
                                          <label
                                            htmlFor={`victims.${index}.female`}
                                            className="form-check-label"
                                          >
                                            Female
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <input
                                            type="radio"
                                            id={`victims.${index}.other`}
                                            name={`victims.${index}.victimGender`}
                                            value="Other"
                                            checked={
                                              formik.values.victims?.[index]
                                                ?.victimGender === "Other"
                                            }
                                            onChange={formik.handleChange(
                                              `victims.${index}.victimGender`
                                            )}
                                            className={`form-check-input ${touched.victims?.[index]
                                              ?.victimGender &&
                                              errors.victims?.[index]
                                                ?.victimGender
                                              ? "is-invalid"
                                              : ""
                                              }`}
                                            disabled={disable}
                                          />
                                          <label
                                            htmlFor={`victims.${index}.other`}
                                            className="form-check-label"
                                          >
                                            Other
                                          </label>
                                        </div>
                                        {touched.victims?.[index]
                                          ?.victimGender &&
                                          errors.victims?.[index]
                                            ?.victimGender ? (
                                          <div className="invalid-feedback">
                                            {errors.victims[index].victimGender}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`victims.${index}.victimAddress`}
                                      >
                                        Victim Address{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`victims.${index}.victimAddress`}
                                        name={`victims.${index}.victimAddress`}
                                        placeholder="Enter Victim Address"
                                        value={
                                          formik.values.victims?.[index]
                                            ?.victimAddress || ""
                                        }
                                        onChange={formik.handleChange(
                                          `victims.${index}.victimAddress`
                                        )}
                                        className={`form-control ${touched.victims?.[index]
                                          ?.victimAddress &&
                                          errors.victims?.[index]?.victimAddress
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {touched.victims?.[index]
                                        ?.victimAddress &&
                                        errors.victims?.[index]?.victimAddress ? (
                                        <div className="invalid-feedback">
                                          {errors.victims[index].victimAddress}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`victims.${index}.information`}
                                      >
                                        Information{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`victims.${index}.information`}
                                        name={`victims.${index}.information`}
                                        placeholder="Enter Information"
                                        value={
                                          formik.values.victims?.[index]
                                            ?.information || ""
                                        }
                                        onChange={formik.handleChange(
                                          `victims.${index}.information`
                                        )}
                                        className={`form-control ${touched.victims?.[index]
                                          ?.information &&
                                          errors.victims?.[index]?.information
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {touched.victims?.[index]?.information &&
                                        errors.victims?.[index]?.information ? (
                                        <div className="invalid-feedback">
                                          {errors.victims[index].information}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`victims.${index}.injuriesSustained`}
                                      >
                                        Injuries Sustained{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`victims.${index}.injuriesSustained`}
                                        name={`victims.${index}.injuriesSustained`}
                                        placeholder="Enter Injuries Sustained"
                                        value={
                                          formik.values.victims?.[index]
                                            ?.injuriesSustained || ""
                                        }
                                        onChange={formik.handleChange(
                                          `victims.${index}.injuriesSustained`
                                        )}
                                        className={`form-control ${touched.victims?.[index]
                                          ?.injuriesSustained &&
                                          errors.victims?.[index]
                                            ?.injuriesSustained
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {touched.victims?.[index]
                                        ?.injuriesSustained &&
                                        errors.victims?.[index]
                                          ?.injuriesSustained ? (
                                        <div className="invalid-feedback">
                                          {
                                            errors.victims[index]
                                              .injuriesSustained
                                          }
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`victims.${index}.description`}
                                      >
                                        Description{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`victims.${index}.description`}
                                        name={`victims.${index}.description`}
                                        placeholder="Enter Description"
                                        value={
                                          formik.values.victims?.[index]
                                            ?.description || ""
                                        }
                                        onChange={formik.handleChange(
                                          `victims.${index}.description`
                                        )}
                                        className={`form-control ${touched.victims?.[index]
                                          ?.description &&
                                          errors.victims?.[index]?.description
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {touched.victims?.[index]?.description &&
                                        errors.victims?.[index]?.description ? (
                                        <div className="invalid-feedback">
                                          {errors.victims[index].description}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label htmlFor={`victims.${index}.value`}>
                                        Value{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`victims.${index}.value`}
                                        name={`victims.${index}.value`}
                                        placeholder="Enter Value"
                                        value={
                                          formik.values.victims?.[index]
                                            ?.value || ""
                                        }
                                        onChange={formik.handleChange(
                                          `victims.${index}.value`
                                        )}
                                        className={`form-control ${touched.victims?.[index]?.value &&
                                          errors.victims?.[index]?.value
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {touched.victims?.[index]?.value &&
                                        errors.victims?.[index]?.value ? (
                                        <div className="invalid-feedback">
                                          {errors.victims[index].value}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`victims.${index}.victimStatement`}
                                      >
                                        Victim Statement{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`victims.${index}.victimStatement`}
                                        name={`victims.${index}.victimStatement`}
                                        placeholder="Enter Victim Statement"
                                        value={
                                          formik.values.victims?.[index]
                                            ?.victimStatement || ""
                                        }
                                        onChange={formik.handleChange(
                                          `victims.${index}.victimStatement`
                                        )}
                                        className={`form-control ${touched.victims?.[index]
                                          ?.victimStatement &&
                                          errors.victims?.[index]
                                            ?.victimStatement
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {touched.victims?.[index]
                                        ?.victimStatement &&
                                        errors.victims?.[index]
                                          ?.victimStatement ? (
                                        <div className="invalid-feedback">
                                          {
                                            errors.victims[index]
                                              .victimStatement
                                          }
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-1 icon-buttons">
                                    <button
                                      className="inner-button add-button"
                                      type="button"
                                      onClick={() => push({})} // Add a new officer
                                      disabled={disable}
                                    >
                                      <i className="pi pi-plus"></i>{" "}
                                    </button>
                                    <button
                                      className="inner-button remove-button"
                                      type="button"
                                      onClick={() => {
                                        if (index > 0) {
                                          remove(index); // Remove the current officer only if index is greater than 0
                                        }
                                      }} // Remove the current officer
                                      disabled={disable}
                                    >
                                      <i className="pi pi-minus"></i>{" "}
                                      {/* PrimeReact Icon */}
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </div>
                    </AccordionTab>

                    <AccordionTab header="Witness Details">
                      <div className="main-form">
                        <FieldArray name="witnesses">
                          {({ push, remove }) => (
                            <>
                              {values?.witnesses?.map((witness, index) => (
                                <div
                                  key={index}
                                  className="index-form row-container"
                                >
                                  <div className="col-11 p-fluid formgrid grid">
                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`witnesses.${index}.witnessName`}
                                      >
                                        Name{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`witnesses.${index}.witnessName`}
                                        name={`witnesses.${index}.witnessName`}
                                        placeholder="Enter Witness Name"
                                        value={
                                          formik.values.witnesses?.[index]
                                            ?.witnessName || ""
                                        }
                                        onChange={formik.handleChange(
                                          `witnesses.${index}.witnessName`
                                        )}
                                        className={`form-control ${touched.witnesses?.[index]
                                          ?.witnessName &&
                                          errors.witnesses?.[index]?.witnessName
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {touched.witnesses?.[index]
                                        ?.witnessName &&
                                        errors.witnesses?.[index]?.witnessName ? (
                                        <div className="invalid-feedback">
                                          {errors.witnesses[index].witnessName}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`witnesses.${index}.witnessAge`}
                                      >
                                        Age{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`witnesses.${index}.witnessAge`}
                                        name={`witnesses.${index}.witnessAge`}
                                        placeholder="Enter Witness Age"
                                        value={
                                          formik.values.witnesses?.[index]
                                            ?.witnessAge || ""
                                        }
                                        onChange={formik.handleChange(
                                          `witnesses.${index}.witnessAge`
                                        )}
                                        className={`form-control ${touched.witnesses?.[index]
                                          ?.witnessAge &&
                                          errors.witnesses?.[index]?.witnessAge
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {touched.witnesses?.[index]?.witnessAge &&
                                        errors.witnesses?.[index]?.witnessAge ? (
                                        <div className="invalid-feedback">
                                          {errors.witnesses[index].witnessAge}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label>
                                        Gender{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <div className="form-check-radio">
                                        <div className="form-check">
                                          <input
                                            type="radio"
                                            id={`witnesses.${index}.male`}
                                            name={`witnesses.${index}.witnessGender`}
                                            value="Male"
                                            checked={
                                              formik.values.witnesses?.[index]
                                                ?.witnessGender === "Male"
                                            }
                                            onChange={formik.handleChange(
                                              `witnesses.${index}.witnessGender`
                                            )}
                                            className={`form-check-input ${touched.witnesses?.[index]
                                              ?.witnessGender &&
                                              errors.witnesses?.[index]
                                                ?.witnessGender
                                              ? "is-invalid"
                                              : ""
                                              }`}
                                            disabled={disable}
                                          />
                                          <label
                                            htmlFor={`witnesses.${index}.male`}
                                            className="form-check-label"
                                          >
                                            Male
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <input
                                            type="radio"
                                            id={`witnesses.${index}.female`}
                                            name={`witnesses.${index}.witnessGender`}
                                            value="Female"
                                            checked={
                                              formik.values.witnesses?.[index]
                                                ?.witnessGender === "Female"
                                            }
                                            onChange={formik.handleChange(
                                              `witnesses.${index}.witnessGender`
                                            )}
                                            className={`form-check-input ${touched.witnesses?.[index]
                                              ?.witnessGender &&
                                              errors.witnesses?.[index]
                                                ?.witnessGender
                                              ? "is-invalid"
                                              : ""
                                              }`}
                                            disabled={disable}
                                          />
                                          <label
                                            htmlFor={`witnesses.${index}.female`}
                                            className="form-check-label"
                                          >
                                            Female
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <input
                                            type="radio"
                                            id={`witnesses.${index}.other`}
                                            name={`witnesses.${index}.witnessGender`}
                                            value="Other"
                                            checked={
                                              formik.values.witnesses?.[index]
                                                ?.witnessGender === "Other"
                                            }
                                            onChange={formik.handleChange(
                                              `witnesses.${index}.witnessGender`
                                            )}
                                            className={`form-check-input ${touched.witnesses?.[index]
                                              ?.witnessGender &&
                                              errors.witnesses?.[index]
                                                ?.witnessGender
                                              ? "is-invalid"
                                              : ""
                                              }`}
                                            disabled={disable}
                                          />
                                          <label
                                            htmlFor={`witnesses.${index}.other`}
                                            className="form-check-label"
                                          >
                                            Other
                                          </label>
                                        </div>
                                        {touched.witnesses?.[index]
                                          ?.witnessGender &&
                                          errors.witnesses?.[index]
                                            ?.witnessGender ? (
                                          <div className="invalid-feedback">
                                            {
                                              errors.witnesses[index]
                                                .witnessGender
                                            }
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`witnesses.${index}.witnessAddress`}
                                      >
                                        Address{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`witnesses.${index}.witnessAddress`}
                                        name={`witnesses.${index}.witnessAddress`}
                                        placeholder="Enter Witness Address"
                                        value={
                                          formik.values.witnesses?.[index]
                                            ?.witnessAddress || ""
                                        }
                                        onChange={formik.handleChange(
                                          `witnesses.${index}.witnessAddress`
                                        )}
                                        className={`form-control ${touched.witnesses?.[index]
                                          ?.witnessAddress &&
                                          errors.witnesses?.[index]
                                            ?.witnessAddress
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {touched.witnesses?.[index]
                                        ?.witnessAddress &&
                                        errors.witnesses?.[index]
                                          ?.witnessAddress ? (
                                        <div className="invalid-feedback">
                                          {
                                            errors.witnesses[index]
                                              .witnessAddress
                                          }
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`witnesses.${index}.witnessInformation`}
                                      >
                                        Information{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`witnesses.${index}.witnessInformation`}
                                        name={`witnesses.${index}.witnessInformation`}
                                        placeholder="Enter Witness Information"
                                        value={
                                          formik.values.witnesses?.[index]
                                            ?.witnessInformation || ""
                                        }
                                        onChange={formik.handleChange(
                                          `witnesses.${index}.witnessName`
                                        )}
                                        className={`form-control ${touched.witnesses?.[index]
                                          ?.witnessInformation &&
                                          errors.witnesses?.[index]
                                            ?.witnessInformation
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {touched.witnesses?.[index]
                                        ?.witnessInformation &&
                                        errors.witnesses?.[index]
                                          ?.witnessInformation ? (
                                        <div className="invalid-feedback">
                                          {
                                            errors.witnesses[index]
                                              .witnessInformation
                                          }
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`witnesses.${index}.relationshipToVictim`}
                                      >
                                        Relationship to Victim{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`witnesses.${index}.relationshipToVictim`}
                                        name={`witnesses.${index}.relationshipToVictim`}
                                        placeholder="Enter Relationship to Victim"
                                        value={
                                          formik.values.witnesses?.[index]
                                            ?.relationshipToVictim || ""
                                        }
                                        onChange={formik.handleChange(
                                          `witnesses.${index}.relationshipToVictim`
                                        )}
                                        className={`form-control ${touched.witnesses?.[index]
                                          ?.relationshipToVictim &&
                                          errors.witnesses?.[index]
                                            ?.relationshipToVictim
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {touched.witnesses?.[index]
                                        ?.relationshipToVictim &&
                                        errors.witnesses?.[index]
                                          ?.relationshipToVictim ? (
                                        <div className="invalid-feedback">
                                          {
                                            errors.witnesses[index]
                                              .relationshipToVictim
                                          }
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                                      <label
                                        htmlFor={`witnesses.${index}.witnessStatement`}
                                      >
                                        Statement{" "}
                                        <span className="error required">
                                          *
                                        </span>
                                      </label>
                                      <InputText
                                        id={`witnesses.${index}.witnessStatement`}
                                        name={`witnesses.${index}.witnessStatement`}
                                        placeholder="Enter Witness Statement"
                                        value={
                                          formik.values.witnesses?.[index]
                                            ?.witnessStatement || ""
                                        }
                                        onChange={formik.handleChange(
                                          `witnesses.${index}.witnessStatement`
                                        )}
                                        className={`form-control ${touched.witnesses?.[index]
                                          ?.witnessStatement &&
                                          errors.witnesses?.[index]
                                            ?.witnessStatement
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        disabled={disable}
                                      />
                                      {touched.witnesses?.[index]
                                        ?.witnessStatement &&
                                        errors.witnesses?.[index]
                                          ?.witnessStatement ? (
                                        <div className="invalid-feedback">
                                          {
                                            errors.witnesses[index]
                                              .witnessStatement
                                          }
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-1 icon-buttons">
                                    <button
                                      className="inner-button add-button"
                                      type="button"
                                      onClick={() => push({})} // Add a new officer
                                      disabled={disable}
                                    >
                                      <i className="pi pi-plus"></i>{" "}
                                    </button>
                                    <button
                                      className="inner-button remove-button"
                                      type="button"
                                      onClick={() => {
                                        if (index > 0) {
                                          remove(index); // Remove the current officer only if index is greater than 0
                                        }
                                      }} // Remove the current officer
                                      disabled={disable}
                                    >
                                      <i className="pi pi-minus"></i>{" "}
                                      {/* PrimeReact Icon */}
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </div>
                    </AccordionTab>
                    <AccordionTab header="Investigation Details">
                      <div className="main-form">
                        <div className="p-fluid formgrid grid">
                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="cctvFootage">
                              CCTV Footage{" "}
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              type="file"
                              multiple
                              id="cctvFootage"
                              name="cctvFootage"
                              onChange={(event) =>
                                handleFileUpload(event, "cctvFootage")
                              }
                              accept=".png, .jpg, .jpeg, .svg"
                              disabled={disable}
                            />
                          </div>

                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="fingerprint">
                              Fingerprint{" "}
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              type="file"
                              multiple
                              id="fingerprint"
                              name="fingerprint"
                              onChange={(event) =>
                                handleFileUpload(event, "fingerprint")
                              }
                              accept=".png, .jpg, .jpeg, .svg"
                              disabled={disable}
                            />
                          </div>

                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="dnaSample">
                              DNA Sample{" "}
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              type="file"
                              multiple
                              id="dnaSample"
                              name="dnaSample"
                              onChange={(event) =>
                                handleFileUpload(event, "dnaSample")
                              }
                              accept=".png, .jpg, .jpeg, .svg"
                              disabled={disable}
                            />
                          </div>

                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="weaponRecovered">
                              Weapon Recovered{" "}
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              id="weaponRecovered"
                              name="weaponRecovered"
                              placeholder="Enter Weapon Recovered"
                              value={formik.values.weaponRecovered}
                              onChange={formik.handleChange}
                              className={`form-control ${formik.touched.weaponRecovered &&
                                formik.errors.weaponRecovered
                                ? "is-invalid"
                                : ""
                                }`}
                              disabled={disable}
                            />
                            {formik.touched.weaponRecovered &&
                              formik.errors.weaponRecovered ? (
                              <div className="invalid-feedback">
                                {formik.errors.weaponRecovered}
                              </div>
                            ) : null}
                          </div>

                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="stolenPropertyRecovered">
                              Stolen Property Recovered{" "}
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              id="stolenPropertyRecovered"
                              name="stolenPropertyRecovered"
                              placeholder="Enter Stolen Property Recovered"
                              value={formik.values.stolenPropertyRecovered}
                              onChange={formik.handleChange}
                              className={`form-control ${formik.touched.stolenPropertyRecovered &&
                                formik.errors.stolenPropertyRecovered
                                ? "is-invalid"
                                : ""
                                }`}
                              disabled={disable}
                            />
                            {formik.touched.stolenPropertyRecovered &&
                              formik.errors.stolenPropertyRecovered ? (
                              <div className="invalid-feedback">
                                {formik.errors.stolenPropertyRecovered}
                              </div>
                            ) : null}
                          </div>

                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="statementTaken">
                              Statement Taken{" "}
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              type="file"
                              multiple
                              id="statementTaken"
                              name="statementTaken"
                              onChange={(event) =>
                                handleFileUpload(event, "statementTaken")
                              }
                              accept=".png, .jpg, .jpeg, .svg"
                              disabled={disable}
                            />
                          </div>

                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="photosTaken">
                              Photos Taken{" "}
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              type="file"
                              multiple
                              id="photosTaken"
                              name="photosTaken"
                              onChange={(event) =>
                                handleFileUpload(event, "photosTaken")
                              }
                              accept=".png, .jpg, .jpeg, .svg"
                              disabled={disable}
                            />
                          </div>

                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="sketchCompositeImages">
                              Sketch Composite Images{" "}
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              type="file"
                              multiple
                              id="sketchCompositeImages"
                              name="sketchCompositeImages"
                              onChange={(event) =>
                                handleFileUpload(event, "sketchCompositeImages")
                              }
                              accept=".png, .jpg, .jpeg, .svg"
                              disabled={disable}
                            />
                          </div>

                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="surveillanceConducted">
                              Surveillance Conducted{" "}
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              type="file"
                              multiple
                              id="surveillanceConducted"
                              name="surveillanceConducted"
                              onChange={(event) =>
                                handleFileUpload(event, "surveillanceConducted")
                              }
                              accept=".png, .jpg, .jpeg, .svg"
                              disabled={disable}
                            />
                          </div>

                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="suspectInterviews">
                              Suspect Interviews{" "}
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              id="suspectInterviews"
                              name="suspectInterviews"
                              placeholder="Enter Suspect Interviews"
                              value={formik.values.suspectInterviews}
                              onChange={formik.handleChange}
                              className={`form-control ${formik.touched.suspectInterviews &&
                                formik.errors.suspectInterviews
                                ? "is-invalid"
                                : ""
                                }`}
                              disabled={disable}
                            />
                            {formik.touched.suspectInterviews &&
                              formik.errors.suspectInterviews ? (
                              <div className="invalid-feedback">
                                {formik.errors.suspectInterviews}
                              </div>
                            ) : null}
                          </div>

                          <div className="field md:col-6 lg:col-6 xl:col-6 responsive-col">
                            <label htmlFor="forensicAnalysis">
                              Forensic Analysis{" "}
                              <span className="error required">*</span>
                            </label>
                            <InputText
                              id="forensicAnalysis"
                              name="forensicAnalysis"
                              placeholder="Enter Forensic Analysis"
                              value={formik.values.forensicAnalysis}
                              onChange={formik.handleChange}
                              className={`form-control ${formik.touched.forensicAnalysis &&
                                formik.errors.forensicAnalysis
                                ? "is-invalid"
                                : ""
                                }`}
                              disabled={disable}
                            />
                            {formik.touched.forensicAnalysis &&
                              formik.errors.forensicAnalysis ? (
                              <div className="invalid-feedback">
                                {formik.errors.forensicAnalysis}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </AccordionTab>
                    <AccordionTab header="Upload Picture">
                      <div className="main-form">
                        <div className="p-fluid upload-content formgrid grid">
                          <div className="field md:col-12 responsive-col">
                            <div>
                              <label htmlFor="">
                                Upload Images
                                <span className="asteric ">&nbsp;*</span>
                              </label>
                              <br />
                              <input
                                type="file"
                                onChange={(event) =>
                                  handlePicturesUpload(event)
                                }
                                accept=".png, .jpg, .jpeg, .svg"
                                multiple
                                className="d-none my-3"
                                disabled={disable}
                              />
                            </div>

                            <div className="block mb-2 upload_images multiple-images">
                              <input
                                type="file"
                                onChange={(event) =>
                                  handlePicturesUpload(event)
                                }
                                accept=".png, .jpg, .jpeg, .svg"
                                multiple
                                disabled={disable}
                              />
                              <div className="upload-grid upload-multiple">
                                {formik.values.pictures?.map((image, index) => (
                                  <div key={index} className="image-preview">
                                    <img src={image} alt={`Image ${index}`} />
                                    <Button
                                      icon="pi pi-times"
                                      type="button"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveImage(index);
                                      }}
                                      disabled={disable}
                                    />
                                  </div>
                                ))}
                                {formik.values.pictures?.length % 3 !== 0 && (
                                  <div className="filler"></div>
                                )}
                              </div>
                            </div>

                            {formik.errors.pictures &&
                              formik.touched.pictures && (
                                <small className="error">
                                  {formik.errors.pictures}
                                </small>
                              )}
                          </div>
                        </div>
                      </div>
                    </AccordionTab>
                  </Accordion>
                  <div className="field-button sticky bottom-0">
                    <Button className="custom-p-button mb-2" label="Save" type="submit" />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </>
  );
};
