import React from "react";
import { Paginator } from "primereact/paginator";

const PaginatorComponent = ({ first, rows, totalRecords, onPageChange }) => {
  const paginationTemplate = {
    layout:
      "CurrentPageReport FirstPageLink RowsPerPageDropdown PrevPageLink PageLinks NextPageLink LastPageLink",

    CurrentPageReport: (options) => {
      return (
        <span className="pagination-template">
          Showing {options.first} - {options.last} of {options.totalRecords}{" "}
          entries
        </span>
      );
    },
  };

  return (
    <Paginator
      template={paginationTemplate}
      first={first}
      rows={rows}
      totalRecords={totalRecords}
      onPageChange={onPageChange}
    />
  );
};

export default PaginatorComponent;
