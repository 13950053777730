import { api } from "../../../../services/api";
export const postsAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getReportById: build.mutation({
      query: (id) => ({
        url: `incident/${id}`,
        method: "GET",
      }),
      providesTags: ["reportDetails"],
    }),
    getAllReports: build.mutation({
      query: (params) => ({
        url: `incident?${params}`,
        method: "GET",
      }),
      providesTags: ["allReports"],
    }),
    deleteReport: build.mutation({
      query: (id) => ({
        url: `incident/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["allReports"],
    }),

    updateReport: build.mutation({
      query: (body) => ({
        url: `incident/${body.id}`,
        method: "PATCH",
        body: body.data,
      }),
      invalidatesTags: ["allReports"],
    }),
    addReport: build.mutation({
      query: (body) => ({
        url: "incident",
        method: "POST",
        body,
      }),
      invalidatesTags: ["allReports"],
    }),
  }),
});

export const {
  useGetReportByIdMutation,
  useGetAllReportsMutation,
  useDeleteReportMutation,
  useUpdateReportMutation,
  useAddReportMutation,
} = postsAPI;
