import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import "./app.css";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { AppTopbar } from "./app/features/components/app_top_bar";
import { AppMenu } from "./app/features/components/app_menu";
import { AppConfig } from "./AppConfig";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./styles/app.scss";
import "./styles/form.scss";
import "./styles/login.scss";
import Dashboard from "./app/features/screens/Dashboard/pages/Dashboard";
import { IncidentReporting } from "./app/features/screens/Incident_Reporting/pages/incident_reporting";
import { IncidentRecords } from "./app/features/screens/Incident_Records/pages/incident_records";
import ReportDetailsDialog from "./app/features/screens/Incident_Records/components/incident_record_details_dialog";
import LoginScreen from "./app/features/screens/auth/login";

const App = () => {
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const [currentRouteLabel, setCurrentRouteLabel] = useState("");
  const userData = localStorage.getItem("user");
  console.log("userData", userData);
  let user;
  if (userData) {
    user = userData;
  }

  const copyTooltipRef = useRef();
  const location = useLocation();
  const history = useHistory();

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    if (copyTooltipRef.current) {
      copyTooltipRef.current.updateTargetEvents();
    }
  }, [location]);

  useEffect(() => {
    localStorage.setItem("currentPath", location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const storedPath = localStorage.getItem("currentPath");
    if (storedPath) {
      history.push(storedPath);
    }
  }, [history]);

  useEffect(() => {
    const routeLabel = findCurrentRouteLabel(location);
    setCurrentRouteLabel(routeLabel);
  }, [location]);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = () => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };
  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const isDesktop = () => {
    return window.innerWidth >= 992;
  };
  const menu = [
    {
      label: "Dashboard",
      icon: "pi pi-th-large",
      to: "/",
    },
    // {
    //   label: "Incident Reporting",
    //   icon: "pi pi-id-card",
    //   to: "/incident_reporting",
    // },
    {
      label: "Incident Record",
      icon: "pi pi-save",
      to: "/incident_records",
    },
  ];

  const findCurrentRouteLabel = (location) => {
    const path = location.pathname;
    console.log("path: ", path);

    for (const group of menu) {
      console.log("group: ", group);
      if (group.to === path) {
        return group.label;
      }

      if (group.items) {
        for (const item of group.items) {
          if (path.startsWith(item.to)) {
            return item.label || group.label;
          }
        }
      }

      // if (group.to && path.startsWith(group.to)) {
      //   return group.label;
      // }
    }

    return "";
  };

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  return (
    <>
      <ToastContainer />
      <Switch>
        {user === undefined ? (
          <Route path="/" component={LoginScreen} />
        ) : (
          <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip
              ref={copyTooltipRef}
              target=".block-action-copy"
              position="bottom"
              content="Copied to clipboard"
              event="focus"
            />
            <div className="layout-sidebar" onClick={onSidebarClick}>
              <AppMenu
                model={menu}
                onMenuItemClick={onMenuItemClick}
                layoutColorMode={layoutColorMode}
              />
            </div>

            <div className="layout-main-container">
              <AppTopbar
                onToggleMenuClick={onToggleMenuClick}
                layoutColorMode={layoutColorMode}
                mobileTopbarMenuActive={mobileTopbarMenuActive}
                onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
                currentRouteLabel={currentRouteLabel}
                user=""
              />
              <div className="layout-main">
                <Route exact path="/" component={Dashboard} />
                {/* to edit */}
                <Route
                  path="/incident_records/incident_reporting/:reportId?"
                  component={IncidentReporting}
                />
                {/* main screen */}
                <Route
                  exact
                  path="/incident_records"
                  component={IncidentRecords}
                />
                {/* for view */}
                <Route
                  path="/incident_records/report_details/:reportId"
                  component={IncidentReporting}
                />
              </div>
            </div>
            <AppConfig
              rippleEffect={ripple}
              onRippleEffect={onRipple}
              inputStyle={inputStyle}
              onInputStyleChange={onInputStyleChange}
              layoutMode={layoutMode}
              onLayoutModeChange={onLayoutModeChange}
              layoutColorMode={layoutColorMode}
              onColorModeChange={onColorModeChange}
            />
            <CSSTransition
              classNames="layout-mask"
              timeout={{ enter: 200, exit: 200 }}
              in={mobileMenuActive}
              unmountOnExit
            >
              <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
          </div>
        )}
      </Switch>
    </>
  );
};

export default App;
