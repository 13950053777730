import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { Ripple } from "primereact/ripple";
import { Badge } from "primereact/badge";
import logo from "../../../assets/theft_reporting/logo.png";
import nlogo from "../../../assets/theft_reporting/nlogo.png";

const AppSubmenu = (props) => {
  const [activeIndex, setActiveIndex] = useState(() => {
    const savedIndex = localStorage.getItem("activeIndex");
    return savedIndex
      ? JSON.parse(savedIndex)
      : props.items.map((item, index) => index); // Open all by default
  });

  useEffect(() => {
    localStorage.setItem("activeIndex", JSON.stringify(activeIndex));
  }, [activeIndex]);

  const onMenuItemClick = (event, item, index) => {
    if (props.onMenuItemClick) {
      props.onMenuItemClick({
        originalEvent: event,
        item: item,
      });
    }

    if (item.items) {
      setActiveIndex((prevState) =>
        prevState.includes(index)
          ? prevState.filter((i) => i !== index)
          : [...prevState, index]
      );
    }
  };

  const renderLinkContent = (item) => {
    let submenuIcon = item.items && (
      <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
    );
    let badge = item.badge && <Badge value={item.badge} />;

    return (
      <React.Fragment>
        <i className={item.icon}></i>
        <span>{item.label}</span>
        {submenuIcon}
        {badge}
        <Ripple />
      </React.Fragment>
    );
  };
  // const renderLinkContent = (item) => {
  //   console.log("item: ", item);
  //   let submenuIcon = item.items && item.items.length > 0 && (
  //     <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
  //   );
  //   let badge = item.badge && <Badge value={item.badge} />;
  //   let customIcon = item.icon && (
  //     <img src={item.icon} alt={item.label} className="custom-menu-icon" />
  //   );
  //   console.log("customIcon: ", customIcon);

  //   return (
  //     <div className="menuitem-content">
  //       {/* {customIcon || <i className={item.icon}></i>} */}
  //       <span>{item.label}</span>
  //       {submenuIcon}
  //       {badge}
  //       <Ripple />
  //     </div>
  //   );
  // };

  const renderLink = (item, i) => {
    let content = renderLinkContent(item);

    const handleClick = (event) => {
      if (item.command) {
        item.command({ originalEvent: event, item: item });
      }

      if (props.onMenuItemClick) {
        props.onMenuItemClick({ originalEvent: event, item: item });
      }
    };

    if (item.to) {
      return (
        <NavLink
          aria-label={item.label}
          role="menuitem"
          className="p-ripple"
          activeClassName="router-link-active router-link-exact-active"
          to={item.to}
          exact
          target={item.target}
          onClick={handleClick}
        >
          {content}
        </NavLink>
      );
    } else {
      return (
        <a
          tabIndex="0"
          aria-label={item.label}
          role="menuitem"
          href={item.url}
          className="p-ripple"
          target={item.target}
          onClick={handleClick}
        >
          {content}
        </a>
      );
    }
  };

  let items =
    props.items &&
    props.items.map((item, i) => {
      let active = activeIndex.includes(i);
      let styleClass = classNames(item.badgeStyleClass, {
        "layout-menuitem-category": props.root,
        "active-menuitem": active && item.items,
      });

      return (
        <li className={styleClass} key={i} role="none">
          <div onClick={(e) => onMenuItemClick(e, item, i)}>
            {renderLink(item, i)}
          </div>
          {item.items && item.items.length > 0 && (
            <CSSTransition
              classNames="layout-submenu-wrapper"
              timeout={{ enter: 1000, exit: 450 }}
              in={active}
              unmountOnExit
            >
              <AppSubmenu
                items={item.items}
                onMenuItemClick={props.onMenuItemClick}
              />
            </CSSTransition>
          )}
        </li>
      );
    });

  return items ? (
    <ul className={props.className} role="menu">
      {items}
    </ul>
  ) : null;
};

export const AppMenu = (props) => {
  console.log("props: ", props.onMenuItemClick);
  return (
    <div className="layout-menu-container">
      <div className="mx-auto logo-section">
        <img src={nlogo} alt="Logo" />
        <h6>Theft Reporting</h6>
      </div>
      <AppSubmenu
        items={props.model}
        className="layout-menu"
        onMenuItemClick={props.onMenuItemClick}
        root={true}
        role="menu"
      />
    </div>
  );
};
