import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputText } from "primereact/inputtext";
import { useGetAllReportsMutation } from "../../Incident_Records/manager/report_service";
import Paginator from "../../../../services/paginator";
import { Calendar } from "primereact/calendar";
import { format } from "date-fns";
import CustomLoading from "../../../components/custom_spinner";
// import { createBrowserHistory } from "history";
import { useHistory } from "react-router-dom"; // Import useNavigate
const DEBOUNCE_DELAY = 500;
export const IncidentRecords = () => {
  const history = useHistory();
  const [editable, setEditable] = useState(0);
  const [detailDialog, setDetailDialog] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [getReportRecords, { data, error, isLoading }] =
    useGetAllReportsMutation();
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0); // Pagination start index
  const [rows, setRows] = useState(10); // Number of rows per page
  const dialogFuncMap = {
    detailDialog: setDetailDialog,
  };
  const [debouncedFilter, setDebouncedFilter] = useState(globalFilter);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilter(globalFilter); // Set debounced value after delay
    }, DEBOUNCE_DELAY);

    // Clear the timeout if the effect is re-run (i.e., if globalFilter changes)
    return () => {
      clearTimeout(handler);
    };
  }, [globalFilter]);
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };
  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    setRows(event.rows);
  };
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const handleGlobalFilterChange = (e) => {
    const searchValue = e.target.value;
    setGlobalFilter(searchValue);
  };

  const actionBody = (row) => {
    console.log("row", row);
    return (
      <div className="flex flex-row">
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          onClick={() => {
            const updatedRow = { ...row, editable: 2 }; // Include the updated editable state here
            history.push({
              pathname: `incident_records/report_details/${row.id}`,
              state: { row: updatedRow, editable: 2 },
            });
          }}
          aria-label="Details"
        />
        <Button
          icon="pi pi-user-edit"
          className="p-button-rounded p-button-primary"
          onClick={() => {
            const updatedRow = { ...row, editable: 1 }; // Include the updated editable state here
            history.push({
              pathname: `incident_records/incident_reporting/${row.id}`,
              state: { row: updatedRow, editable: 1 },
            });
          }}
          aria-label="Details"
        />
      </div>
    );
  };

  useEffect(() => {
    let queryData = [];
    let from;
    let to;

    // Check if globalFilter has a value and add it to queryData
    if (debouncedFilter) {
      queryData.push(`search=${debouncedFilter}`);
    }
    if (page) {
      queryData.push(`page=${page}`);
    }
    // Check if both fromDate and toDate have values, or either one individually
    if (fromDate && toDate) {
      from = format(fromDate, "yyyy-MM-dd");
      to = format(toDate, "yyyy-MM-dd");
      queryData.push(`fromDate=${from}&toDate=${to}`);
    } else if (fromDate) {
      from = format(fromDate, "yyyy-MM-dd");
      queryData.push(`fromDate=${from}`);
    } else if (toDate) {
      to = format(toDate, "yyyy-MM-dd");
      queryData.push(`toDate=${to}`);
    }
    queryData.push(`limit=10`);

    const filter = queryData.join("&");

    // Call the function with the filtered queryData object
    getReportRecords(filter);
  }, [debouncedFilter, fromDate, toDate, page]);

  return (
    <>
      <ToastContainer />
      <div className="card">
        <div className="grid align-items-center mb-3">
          <div className="md:col-3 lg:col-3 sm:col-12 col-12">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                className="w-full"
                value={globalFilter}
                onChange={handleGlobalFilterChange}
                placeholder="Search"
              />
            </span>
          </div>
          <div className="md:col-9 lg:col-9 sm:col-12 col-12 flex flex-wrap justify-content-end">
            <div className="md:col-4 lg:col-4 sm:col-12 col-12 p-2">
              <Calendar
                type="date"
                id="date"
                placeholder="Select From Date"
                value={fromDate}
                className="w-full"
                onChange={(e) => setFromDate(e.value)}
                showIcon
              />
            </div>
            <div className="md:col-4 lg:col-4 sm:col-12 col-12 p-2">
              <Calendar
                type="date"
                id="buttondisplay"
                value={toDate}
                className="w-full"
                onChange={(e) => setToDate(e.value)}
                placeholder="Select To Date"
                showIcon
              />
            </div>
            <div className="md:col-2 lg:col-2 sm:col-12 col-12 p-2">
              <Button
                className="custom-p-button w-full"
                label="Add"
                icon="pi pi-plus"
                onClick={() => {
                  history.push({
                    pathname: `incident_records/incident_reporting`,
                    state: { editable: false },
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className="card">
          {isLoading ? (
            <CustomLoading isLoading={isLoading} />
          ) : (
            <>
              <DataTable
                scrollable
                scrollHeight="500px"
                emptyMessage="No record found."
                loading={isLoading}
                value={data?.data?.data}
                className="custom-data-table"
              >
                <Column field="incidentNumber" header="Incident Number" />
                <Column field="incidentType" header="Incident Type" />
                <Column
                  header="Officer Name"
                  body={(rowData) =>
                    rowData?.officer?.[0]?.officerName || "N/A"
                  }
                />
                <Column
                  header="Suspect Name"
                  body={(rowData) => rowData?.suspect?.[0]?.name || "N/A"}
                />
                <Column
                  header="Victim Name"
                  body={(rowData) => rowData?.victim?.[0]?.victimName || "N/A"}
                />
                <Column
                  header="Witness Name"
                  body={(rowData) =>
                    rowData?.witness?.[0]?.witnessName || "N/A"
                  }
                />
                <Column
                  header="Weapon"
                  body={(rowData) =>
                    rowData?.mode_of_operation?.[0]?.weapons || "N/A"
                  }
                />
                <Column field="status" header="Status" />
                <Column field="location" header="Location" />
                <Column field="action" body={actionBody} header="Action" />
              </DataTable>
              <Paginator
                first={first}
                rows={rows}
                totalRecords={data?.data?.total}
                onPageChange={onPageChange}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
