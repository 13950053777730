import { api } from "../../../../services/api";
export const postsAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllReports: build.mutation({
      query: (params) => ({
        url: `report?${params}`,
        method: "GET",
      }),
      providesTags: ["allReports"],
    }),
    getCities: build.query({
        query: () => ({
          url: `city`,
          method: "GET",
        }),
        providesTags: ["allReports"],
      }),
      getEyeColor: build.query({
        query: () => ({
          url: `eyeColor`,
          method: "GET",
        }),
        providesTags: ["allReports"],
      }),
      getHairColors: build.query({
        query: () => ({
          url: `hairColor`,
          method: "GET",
        }),
        providesTags: ["allReports"],
      }),
      getIncidentTypes: build.query({
        query: () => ({
          url: `incidentType`,
          method: "GET",
        }),
        providesTags: ["allReports"],
      }),
      getStatus: build.query({
        query: () => ({
          url: `status`,
          method: "GET",
        }),
        providesTags: ["allReports"],
      }),
   
  }),
});

export const {
  useGetAllReportsMutation,
  useGetCitiesQuery,
  useGetEyeColorQuery,
  useGetHairColorsQuery,
  useGetIncidentTypesQuery,
  useGetStatusQuery
} = postsAPI;
