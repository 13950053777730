import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";

export default function CustomLoading() {
  return (
    <div className="custom-overlay">
      <div className="custom-spinner-container">
        <div className="custom-spinner"></div>
      </div>
    </div>
  );
}
